@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
body {
  color: #6e84a3 !important;
  background-color: #f8f9fa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  font-family: "Quicksand", sans-serif;
}

.main-container {
  min-height: calc((100vh) - 340px);
}

#header .navbar-nav {
  margin-left: auto;
}

#header li {
  float: left;
  text-align: right;
}

#header li a {
  color: #e9f2f2;
}

.register {
  text-decoration: none !important;
}

.drop-link {
  text-decoration: none !important;
}

.drop-link:hover,
.drop-link.active {
  color: white !important;
}

#header li:first-child a {
  text-decoration: underline;
}
.link-buttons .link-bt {
  color: white !important;
  background-color: black !important;
  width: 403px !important;
  /* display: block !important; */
  margin-top: 10px !important;
  height: 70px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  cursor: pointer;
  text-decoration: none;
}
.link-buttons .link-bt:hover {
  background-color: rgb(42, 42, 42) !important;
}
.title-faqs {
  color: black !important;
  margin-top: 0px !important;
}

#header #create-event {
  color: white;
  float: right;
  border: 2px solid;
}

/* FORMS */
.select-search {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

.select-search.is-disabled {
  opacity: 0.5;
}

.select-search__value {
  position: relative;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 8px;
  top: 14px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  border-right: 2px solid #495057;
  border-bottom: 2px solid #495057;
  pointer-events: none;
}

.select-search.is-invalid > div > .select-search__input {
  border-color: red;
}

.select-search__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search__input:hover {
  border-color: #007bff;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  height: 180px;
  overflow: auto;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}
.carousel-indicators {
  display: none !important;
}
.select-search__options {
  list-style: none;
  margin-left: -40px;
}
.modal-content a {
  color: #26ad85 !important;
}
.modal-content a:hover {
  color: #26ad85 !important;
}

.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

.select-search__option {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #007bff;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: #007bff;
  color: white !important;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #007bff;
  color: #fff;
}

.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

.detail-event {
  grid-gap: 32px;
  gap: 32px;
}

#main-banner {
  height: 400px;
  background: white;
  overflow: hidden;
}

.banner-short {
  border-radius: 13px !important;
}

@media (min-width: 501px) and (max-width: 768px) {
  #main-banner {
    height: 655px;
  }

  #banner-content {
    display: grid;
    height: 655px !important;
  }

  #banner-img {
    height: 350px !important;
  }
}

@media (max-width: 500px) {
  #main-banner {
    height: 495px;
  }

  .main-banner-zig {
    height: 450px !important;
  }

  .banner-short {
    height: 495px !important;
  }

  .banner-short-zig {
    height: 450px !important;
  }
}

@media (max-width: 375px) {
  #main-banner {
    height: 470px;
  }

  .main-banner-zig {
    height: 420px !important;
  }

  .banner-short {
    height: 470px !important;
  }

  .banner-short-zig {
    height: 420px !important;
  }
}

@media (max-width: 320px) {
  #main-banner {
    height: 475px;
  }

  .main-banner-zig {
    height: 425px !important;
  }

  .banner-short {
    height: 475px !important;
  }

  .banner-short-zig {
    height: 425px !important;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  #main-banner {
    height: 635px;
  }

  #banner-content {
    display: grid;
    height: 635px !important;
  }

  #banner-img {
    height: 300px !important;
  }
}

@media (min-width: 769px) and (max-width: 1000px) {
  #banner-img {
    width: 67% !important;
  }

  .banner-infos {
    width: 33% !important;
  }

  .banner-infos h2 {
    font-size: 18px !important;
  }

  .banner-date {
    font-size: 16px !important;
  }
}

#main-banner #banner-background {
  width: 120%;
  margin-left: -10%;
  opacity: 1;
  height: 470px;
  position: absolute;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

#banner-content {
  height: 350px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  box-shadow: rgba(25, 31, 40, 0.15) 0px 6px 24px 0px;
  background-color: rgb(255, 255, 255);
  position: relative;
  padding: 0px !important;
  overflow: hidden;
  background: transparent !important;
}
#main-events h2,
#near-events h2 {
  font-size: 1.75rem !important;
  color: #12263f !important;
  text-align: left;
  border-bottom: 5px solid #26ad85;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bold;
}
#header {
  background: black !important;
}
.events-list .jHtPU {
  display: none !important;
}
#main-bar .form-control {
  border-radius: 50px;
  height: 42px;
  background-color: color(srgb 0.2338 0.55 0.4563);
  border: none;
  color: white !important;
}
#cart-sidebar {
  border-radius: 20px !important;
  background: transparent !important;
  box-shadow: none !important;
}
#cart-sidebar .ticket {
  background-color: white !important;
  border-radius: 0px !important;
  margin-bottom: 25px;
}
#cart-resume .accordion {
  background-color: transparent !important;
}
#cart-sidebar .card,
#event-content #cart-sidebar #cart-resume {
  background-color: transparent !important;
}
#cart-sidebar #cart-ammount {
  background-color: white;
  color: black;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
#cart-sidebar #cart-resume {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
#cart-sidebar .ticket-control button {
  border: 2px solid black;
}
#cart-sidebar .ticket-control button:last-child {
  border-color: black !important;
  color: black !important;
  background-color: transparent !important;
  border: 2px solid black !important;
}
#cart-sidebar .ticket-name {
  flex: 0 0 100%;
  margin-bottom: 5px;
  color: rgb(18, 38, 63);
  font-weight: bold;
}
#cart-sidebar .ticket {
  justify-content: start !important;
}
#cart-sidebar h4 {
  color: #12263f;
  text-align: left;
  font-size: 1.25rem;
  border-bottom: 1px dashed color(srgb 0.81 0.8522 0.905);
  width: 100%;
  padding: 10px;
}
#event-content #cart-sidebar .bt-checkout {
  padding: 0;
  margin: 0;
  width: 100%;
  margin: 0px !important;
  margin-top: 10px !important;
  height: 40px;
  background-color: black !important;
  border-radius: 0px;
}
#event-content #cart-sidebar .bt-checkout:hover {
  background-color: black !important;
}
#cart-sidebar #ticket-info .ticket-line {
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
  border-bottom: 1px dashed rgb(235, 235, 235);
  padding-bottom: 10px;
}
#cart-sidebar #ticket-info .ticket-line:last-child {
  border-bottom: transparent;
}
#cart-sidebar .cupom-button {
  background-color: black !important;
  color: white !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}
#cart-sidebar #cart-ammount span {
  font-size: initial;
}
#cart-sidebar .ticket-control button:hover {
  background-color: rgb(98, 98, 98) !important;
  color: white !important;
}
#main-bar .container div:last-child .form-control {
  background-color: white !important;
  color: #198e6b !important;
  border-radius: 0px !important;
}
#main-bar .form-control::placeholder {
  color: white;
}
.event-list-row div {
  border-radius: 0px !important;
}
.event-list-row div div div div:last-child div {
  color: #2e2b3b;
  font-size: 0.75rem;
  overflow-wrap: break-word;
  width: 75%;
  margin-left: 25%;
  text-transform: uppercase;
  font-weight: bold !important;
}
.event-list-row div div div div:last-child div div {
  margin-left: 0px;
  width: 100%;
  font-weight: normal !important;
  text-transform: none;
}
.event-list-row div div div div:first-child div div:first-child {
  background-color: transparent !important;
  transition: all 100ms ease-in-out;
  flex-direction: initial;
  font-size: 1rem !important;
  width: 90px;
  margin-left: -15px;
  margin-top: 210px;
}
.event-list-row
  div
  div
  div
  div:first-child
  div
  div:first-child
  span:first-child {
  font-size: 2.05rem;
  font-weight: bold !important;
  margin: 0px 2px 0px 0px;
  color: black;
  display: block;
}
.event-list-row
  div
  div
  div
  div:first-child
  div
  div:first-child
  span:last-child {
  font-size: 0.85rem;
  font-weight: normal !important;
  margin: 60px 2px 0px -40px;
  display: block;
  background-color: black;
  padding: 1px 5px;
  border-radius: 0px;
}
.event-list-row div div div:first-child div:first-child {
  border-radius: 0px;
}
#banner-img {
  background-size: cover;
  border-radius: 0px;
  width: 70%;
  height: 100%;
  position: relative;
  background-position: center;
  float: left;
}

.banner-infos {
  position: relative;
  padding: 20px;
  width: 30%;
  height: 350px;
  float: right;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: column;
  color: #98afbc;
}

.carousel-indicators {
  display: none;
}

.banner-infos span {
  display: block;
}

.banner-infos h2 {
  color: #12263f !important;
}

.banner-infos .banner-date {
  font-size: 1.2rem;
  color: white !important;
  font-family: "Quicksand", sans-serif;
}

.banner-infos .banner-place {
  line-height: 1.5rem;
  font-size: 0.85rem;
  word-break: break-word;
}

.banner-infos .banner-place i {
  font-size: 1rem;
}

.banner-infos button {
  border-radius: 10px;
  color: white;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
}

.banner-infos button i {
  margin-right: 10px;
}

.banner-infos .share {
  background: white;
  border-radius: 34px;
  top: 35px;
  height: 45px;
  width: auto;
  margin: auto 15px 0px 15px;
  text-align: center;
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 5px 10px 3px rgba(186, 202, 211, 0.4);
}

.banner-infos .share a {
  background: #98afbc;
  border-radius: 30px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-infos .share i {
  color: white;
  display: inline-block;
  font-size: 14px;
}

@media (max-width: 768px) {
  .carousel {
    width: 100%;
    margin-left: 0px;
  }

  #footer,
  #footer ul li,
  #footer .col-md-3 {
    text-align: center !important;
    justify-content: center;
  }

  .details ul {
    min-height: auto !important;
  }

  #banner-content {
    border-radius: 0 !important;
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 3rem;
    background-color: transparent;
  }

  .banner-infos {
    height: 120px;
    padding: 5px 15px 0 15px;
    width: 100%;
    color: transparent;
  }

  #main-banner {
    margin-top: 0rem;
    height: auto;
    padding: 0;
    background-color: transparent;
  }

  #main-banner #banner-img {
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    margin-bottom: 10px;
  }

  .banner-infos {
    padding: 10px;
    background-color: transparent;
    grid-gap: 16px;
    gap: 16px;
    height: auto;
  }

  .banner-infos .name-date {
    height: auto;
    grid-gap: 8px;
    gap: 8px;
  }

  .banner-infos .interaction {
    grid-gap: 4px;
    gap: 4px;
  }

  .banner-infos .info {
    grid-gap: 8px;
    gap: 8px;
  }

  .banner-infos h2 {
    font-size: 25px;
  }

  .banner-infos .banner-date {
    font-size: 1rem;
  }

  .banner-infos .banner-place {
    font-size: 0.85rem;
    display: inline !important;
  }

  .banner-infos .banner-age {
    display: none !important;
  }

  .banner-infos button {
    font-size: 16px;
    line-height: 16px;
  }

  .banner-infos button i {
    margin-right: 0px;
  }
}

.container {
  position: relative;
  height: auto;
}

#main-bar .dropdown {
  width: 50%;
  float: right;
}

#main-bar .dropdown button {
  background: none;
  border: none;
}

#main-events,
#near-events {
  margin: 20px auto;
  text-align: center;
}

#main-events h2,
#near-events h2 {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

#main-events h2 {
  text-align: center;
}

#near-events {
  min-height: 600px;
}

.near-events-searched {
  margin: 20px auto;
  text-align: start;
}

.events-list {
  margin-top: 10px;
}

.events-list .event-list-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.event-card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 16px 64px -16px rgb(46 55 77 / 10%);
  height: 315px;
  width: 260px;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  background: white;
  transition-duration: 0.2s;
}

@media (max-width: 768px) {
  .event-card {
    width: 240px;
  }
}

.event-card:hover {
  box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.25);
  margin-top: 2px;
}

.event-card .event-img {
  height: 210px;
  /* background: url(../img/banner2.jpg); */
  background-size: cover;
}

.event-card .event-actions {
  position: absolute;
  right: 10px;
  top: 155px;
}

.event-card .event-actions a {
  text-decoration: none;
  background: white;
  color: #0050c3;
  border-radius: 50px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin: 5px;
  float: left;
  position: relative;
  box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.25);
}

.event-date {
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 8px;
  color: white;
  box-shadow: 0px 7px 10px 0px #0000004f;
  width: 70px;
  height: 70px;
  text-align: center;
  display: flex;
  flex-direction: column;
  transition: background-color 0.2s ease;
}

.event-date .day {
  font-size: 25px;
  line-height: 40px;
  font-weight: 700;
  color: white;
}

.event-date .month {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: lighter;
  line-height: 20px;
}

.event-card .event-name {
  font-size: 1rem;
  line-height: initial;
  font-family: "Quicksand", sans-serif;
  display: block;
}

.event-card .event-place {
  font-size: 15px;
  font-weight: lighter;
  display: block;
}

.event-card .event-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px 15px 10px 15px;
  height: 90px;
  color: #98afbc;
  text-align: left;
}

.event-card .event-footer .banner-place {
  font-size: 0.8rem;
}

#near-events #more-events {
  background: black !important;
  color: white;
  border-radius: 0px;
  padding: 10px 40px;
  margin-top: 20px;
  font-size: 0.8rem;
}

#event-content {
  margin: 40px auto;
}

#footer {
  color: #e9f2f2;
  background-color: black !important;
  width: 100%;
  min-height: 70px;
  font-size: 14px;
  padding: 30px 100px 10px 100px;
  bottom: 0px;
  position: relative;
}

#footer h2 {
  color: white;
  margin-top: 0px;
  font-size: 16px;
}

#footer ul {
  padding-left: 0px;
}

#footer ul li {
  list-style: none;
}

#footer a {
  color: #e9f2f2;
  display: block;
}

#footer-logo {
  width: 90px;
  margin-bottom: 20px;
}

#footer hr {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

/* .footer-tel {
  text-align: right;
  letter-spacing: 0;
  font-size: 24px;
  margin-bottom: -8px;
}
.footer-address {
  text-align: right;
  font: 'Light' 13px/21px 'Roboto';
  letter-spacing: 0;
  color: #FFFFFF !important;
  opacity: 1 !important;
  font-size: 13px;
}
.footer-tel span {
  font-size: 17px !important;
} */

#event-content #event-description {
  max-width: 100% !important;
  overflow: auto;
  /* height: auto !important; */
}

#event-content #event-description #event-header,
#event-content #event-description hr {
  display: none;
}

#event-content #event-description p,
#event-content #event-description p span {
  font-size: 0.9rem !important;
  word-break: break-word;
}

#event-content #event-description img {
  max-width: 100%;
  height: auto;
}

.checkout {
  margin-top: 40px;
}

#spiner-loading {
  left: 50%;
  top: 50%;
  position: absolute;
  margin-left: -40px;
  /* -1/2 width */
  margin-top: -40px;
  /* -1/2 height */
}

#drop-down {
  color: #e9f2f2 !important;
  font-size: 14px;
  margin-top: 6px;
  border: none;
  font-family: "Quicksand", sans-serif;
}

.modalBtn {
  color: white !important;
  font-size: 16px !important;
  margin-top: 2px;
  background-color: transparent;
  border: none;
  font-family: "Quicksand", sans-serif !important;
}

.locationBtn {
  color: #6e84a3 !important;
  font-size: 16px !important;
  margin-top: 2px;
  background-color: transparent;
  border: none;
  font-family: "Rubik", sans-serif !important;
}

button:focus {
  outline: none !important;
}

.discount-container {
  background-color: #dee9e9 !important;
  border: none !important;
  display: flex;
  direction: row;
  padding: 0px 20px 20px 20px;
}

.discount-btn {
  margin-left: 5px;
}

.discount-coupon-applied {
  color: #008656;
}

.change-cupom {
  margin-top: 15px;
  width: 90%;
}

.strong {
  font-weight: bolder;
}

.btn-login {
  background-color: transparent;
  border: none;
  color: blue;
  font-family: "Quicksand", sans-serif !important;
  font-size: 14px;
  font-weight: bold;
}

.fale-click {
  cursor: pointer !important;
}

.nav-link-btn {
  border-radius: 0px !important;
  text-align: center;
  width: 100% !important;
  color: black !important;
  font-family: "Quicksand", sans-serif !important;
  font-size: 1rem !important;
  margin-top: 40px !important;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  display: inline-block;
  font-weight: bold !important;
  text-decoration: none !important;
  position: absolute;
  bottom: 20px;
  background-color: white !important;
}

.banner-infos .nav-link-btn {
  border-radius: 10rem !important;
  padding: 0.35rem 1.5rem;
  font-size: 0.9rem !important;
}

.ticket-icon {
  margin-right: 10px;
}

.loading-payment {
  z-index: 99999;
  position: absolute;
  min-width: 100% !important;
  min-height: 720px !important;
  background: #e9f2f2;
}

.loading-container {
  min-width: 100% !important;
  min-height: 720px !important;
  background: #e9f2f2;
}

/*CSS da pagina meus tickets */

/* CSS de dispositivos mobile  */

@media (max-width: 2000px) {
  .loading-container {
    min-width: 100% !important;
    min-height: 720px !important;
    background: #e9f2f2;
  }
}

@media (max-width: 1000px) {
  #banner-img {
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
    background-position: center;
    float: left;
  }
}

@media (min-width: 992px) {
  .banner-infos .share {
    padding: 0px 30px;
  }
}

@media (max-width: 991px) {
  #event-content #event-description {
    order: 1;
  }
}

@media (max-width: 768px) {
  #event-description {
    padding: 0;
  }

  .period {
    margin: 10px 0;
  }

  .cupom-button:hover {
    text-decoration: none !important;
    color: #12263f !important;
    border-color: transparent;
  }

  .content {
    padding: 0px 24px;
    grid-gap: 32px;
    gap: 32px;
  }
}

@media (max-width: 400px) {
  .header-list {
    float: none;
    text-align: left !important;
    color: #e9f2f2 !important;
  }

  .navbar-brand img {
    width: 2.063rem;
    height: 2.5rem;
  }

  .location-modal .modal-header {
    padding: 1rem;
  }

  .modal-flow {
    padding: 0.3rem !important;
  }

  .mobile {
    padding: 0.3rem !important;
  }

  .location-modal .new-modal-title {
    padding: 0.2rem !important;
  }
}

@media (max-width: 400px) {
  /* .footer-tel {
    font-size: 21px;
    margin-bottom: 4px;
  } */
  .loading-container {
    min-width: 100% !important;
    min-height: 600px !important;
    background: #e9f2f2;
  }
}

@media (max-width: 500px) {
  /*banner && caroussel */
  #banner-content {
    display: flex;
    flex-direction: column;
  }

  #footer {
    padding: 30px 30px 10px 30px;
  }

  #footer-logo {
    width: 50px;
  }

  .interaction {
    display: none !important;
  }

  #banner-img {
    background-size: cover;
    width: calc(100% - 20px);
    margin-left: 0px;
    width: 100%;
    height: 40%;
    background-position: center;
    background-color: #000000;
    background-repeat: no-repeat;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .banner-infos h2 {
    font-size: 18px !important;
  }

  .banner-infos span {
    display: block;
  }

  .banner-infos .banner-date {
    margin-top: -5px;
    font-size: 15px !important;
    font-family: "Quicksand", sans-serif;
  }

  .banner-infos .banner-place {
    font-size: 13px !important;
  }

  #place-ellips-none {
    max-width: 160px;
    display: block;
    line-height: 15px;
    text-wrap: pretty;
  }

  .banner-infos button {
    border-radius: 10px;
    color: white;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
  }

  .banner-infos .nav-link-btn {
    position: absolute;
    right: 15px;
    bottom: 10px;
  }

  /*cards main */
  .event-card {
    background: white;
    position: relative;
    overflow: hidden;
    float: left;
    border-radius: 8px;
    background-position: top;
    box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.1);
    max-height: 110px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: ease;
    transition-duration: 0.2s;
  }

  .event-card:hover {
    box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.25);
    margin-top: 2px;
  }

  .event-card .event-img {
    height: 80px;
    width: 80px;
    margin-top: 16px;
    max-width: 100px;
    max-height: 100px;
    margin-left: 80px;
    border-radius: 50%;
    background-size: cover;
  }

  .event-card .event-actions {
    position: absolute;
    right: 10px;
    top: 155px;
    display: none;
  }

  .event-card .event-actions a {
    text-decoration: none;
    background: white;
    color: #0050c3;
    border-radius: 50px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin: 5px;
    float: left;
    position: relative;
    box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.25);
  }

  .event-card:hover .event-date {
    background: #0050c3;
  }

  .event-card .day {
    font-size: 25px;
    line-height: 60px;
    font-weight: 700;
    color: white;
    margin-top: -5px;
  }

  .event-card .month {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: lighter;
    line-height: 10px;
    margin-left: 0px;
    margin-top: -5px;
  }

  .event-card .event-name {
    max-height: 200px;
    margin-top: 12px;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .event-card .event-place {
    font-size: 15px;
    font-weight: lighter;
    display: block;
  }

  .event-card .event-footer {
    padding: 6px 2px 2px 6px;
    position: absolute;
    top: 0px;
    height: 100px;
    width: calc(100% - 170px);
    margin-left: 170px;
    left: 0;
    background: transparent;
    color: #98afbc;
    text-align: left;
  }

  .event-footer .banner-date {
    display: none;
  }

  .event-footer .footer-info-mobile {
    margin-top: -20px;
  }

  .event-footer .footer-info-mobile .banner-place {
    position: absolute;
    bottom: 7px;
    max-width: 160px;
    word-wrap: break-word;
  }

  .neighbord {
    display: none;
  }
}

/* TERMS */
.terms h1 {
  font-size: 1.5rem;
}

.terms p,
.terms h4 {
  font-size: 0.8rem;
  width: 100%;
}

/* CUSTOM */
.alert-primary {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5;
}

.alert-secondary {
  color: #fff;
  background-color: #6e84a3;
  border-color: #6e84a3;
}

.alert-success {
  color: #fff;
  background-color: #00d97e;
  border-color: #00d97e;
}

.alert-danger {
  color: #fff;
  background-color: #e63757;
  border-color: #e63757;
}

.alert-warning {
  color: #283e59;
  background-color: #f6c343;
  border-color: #f6c343;
}

.alert-info {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}

.alert-light {
  color: #283e59;
  background-color: #edf2f9;
  border-color: #edf2f9;
}

.alert-dark {
  color: #fff;
  background-color: #12263f;
  border-color: #12263f;
}

/* CUSTOM */
.card {
  margin-bottom: 1.5rem;
  border-color: #edf2f9;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}

.badge {
  padding: 0.33em 0.5em;
  font-size: 76%;
  font-weight: 400;
  border-radius: 0.375rem;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-soft-primary {
  color: #2c7be5;
  background-color: #d5e5fa;
}

.badge-soft-secondary {
  color: #6e84a3;
  background-color: #e2e6ed;
}

.badge-soft-success {
  color: #00d97e;
  background-color: #ccf7e5;
}

.badge-soft-info {
  color: #39afd1;
  background-color: #d7eff6;
}

.badge-soft-warning {
  color: #f6c343;
  background-color: #fdf3d9;
}

.badge-soft-danger {
  color: #e63757;
  background-color: #fad7dd;
}

.badge-soft-light {
  color: #edf2f9;
  background-color: #fbfcfe;
}

.badge-soft-dark {
  color: #12263f;
  background-color: #d0d4d9;
}

.cupom-button {
  padding: 10px 0px;
  background-color: #dee9e9 !important;
  border: 1px solid #dee9e9;
  border-radius: 0px !important;
  color: #12263f;
}

.cupom-button:hover {
  text-decoration: underline;
  color: #12263f;
  border-color: transparent;
}

.cupom-button:active {
  text-decoration: underline;
  color: #12263f;
  border-color: transparent;
}

.cupom-button:focus {
  border-color: transparent;
  color: #12263f;
}

.fadeIn {
  animation: fade-in 0.5s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.react-inputs-validation__msg_identifier {
  color: #dc3545 !important;
  font-size: 15px;
  margin-top: -3px;
}

.error-message {
  color: #dc3545 !important;
  font-size: 15px;
  margin-top: 0px;
}

.bt-checkout:disabled {
  background-color: #98afbc !important;
}

.location-btn {
  border-radius: 10rem;
  border: 1px solid #ffffff50;
  padding: 0.5rem 1.25rem;
  font-size: 0.9rem !important;
}
.location-btn:hover {
  background: #ffffff20;
}
.location-modal .modal-content {
  padding: 0.25rem;
  font-weight: normal;
}
.location-modal .new-modal-title {
  font-weight: normal;
  font-size: 1.25rem !important;
  color: #12263f !important;
}

.location-modal .modal-header {
  border: none;
}

.location-modal .close-icon {
  font-size: 1.5rem;
}

.location-search {
  border: 1px solid #dee2e8;
  border-radius: 0.25rem;
}

.location-search input {
  border: none !important;
  font-size: 1rem;
}

.swal2-title {
  font-size: 1.5rem !important;
  color: #12263f !important;
}

.swal2-content {
  color: #6e84a3 !important;
}

.swal2-icon {
  color: #6e84a3 !important;
}

#loading {
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  transition: 1s;
}
@media screen and (max-width: 991px) {
  #loading {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 768px) {
  #loading {
    height: 100%;
  }
}
#loading.esconde {
  display: none;
}
#loading-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
}
#loading-border {
  animation: expansion 2s infinite linear;
  position: absolute;
  border-radius: 100px;
}
@keyframes expansion {
  0% {
    width: 4rem;
    height: 4rem;
  }
  50% {
    width: 4rem;
    height: 4rem;
  }
  100% {
    width: 4rem;
    height: 4rem;
  }
}
#loading-icon {
  animation: rotation 2s infinite linear;
  width: 5rem;
  font-size: 3rem;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
#loading-icon span {
  display: block;
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50px;
}
#loading p {
  margin: 0;
}
#loading p:nth-child(2) {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 1rem;
}

:root {
  --ticket-blue: #0050c3;
  --btn-white: #ffffff;
  --common-grey: rgb(110, 132, 163, 0.68);
  --sweet-alert-title: #12263f;
  --button-radios: 4px;
  --sweet-alert-description: rgba(110, 132, 163, 0.68);
}

.custom-input {
  border: 0.1px solid #6e84a31f;
  background-color: transparent;
  border-radius: 0 0.625rem 0.625rem 0;
  outline: 0;
  border-left: none;
}

.input-city-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 0.5px;
  gap: 0.5px;
}

.swall-title {
  color: var(--sweet-alert-title);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  grid-gap: var(--sweet-alert-gap, 20px);

  gap: var(--sweet-alert-gap, 20px);

  /* Light/Typography/H4 */
  font-size: 32px;
  font-style: normal;
  font-weight: 550;
  line-height: 40px; /* 125% */
  letter-spacing: 0.25px;
}

.swall-description {
  color: var(--sweet-alert-description);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  padding: 1.5rem;
  /* Light/Typography/Body 1 */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
}

.x-close {
  color: var(--common-grey) !important;
}

.modal-header {
  padding: 0;
}

.location-title {
  margin-bottom: 1rem;
}

.button-div {
  display: flex;
  align-items: baseline;
}

.my-location-button {
  color: var(--common-grey);
  font-size: 16px !important;
  font-weight: 400;
  background-color: transparent;
  border: none;
  line-height: 24px;
  padding: 0;
  border: none;
}

.states-list-item {
  color: var(--common-grey) !important;
  font-size: 16px !important;
  font-weight: 400;
  background-color: transparent;
  border: none;
  line-height: 24px;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.search-input {
  margin-top: 1rem;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--common-grey);
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--common-grey);
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--common-grey);
}

.swal2-modal {
  display: flex;
  width: 576px;
  padding: var(--sweet-alert-padding, 32px);
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: center;
  border-radius: var(--p-t-scale-x, 4px);
  background: var(--sweet-alert-background, #fff);
  box-shadow: 0px 8px 10px 0px rgba(18, 38, 63, 0.03);
}

.btn-swall-confirm {
  background-color: var(--ticket-blue);
  color: var(--btn-white);
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--ticket-blue);
  border: none;
  font-size: 1rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  order: 2;
  font-style: normal;

  display: flex;
  padding: var(--button-py, 0.5rem) var(--button-px, 2rem);
  justify-content: center;
  align-items: center;
  grid-gap: var(--button-gap, 0.5rem);
  gap: var(--button-gap, 0.5rem);
}

.btn-swall-deny {
  color: var(--ticket-blue);
  background-color: var(--btn-white);
  height: 40px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  order: 1;
  margin-right: 10px;
  font-style: normal;

  display: flex;
  padding: var(--button-py, 0.5rem) var(--button-px, 2rem);
  justify-content: center;
  align-items: center;
  grid-gap: var(--button-gap, 0.5rem);
  gap: var(--button-gap, 0.5rem);

  border-radius: var(--button-radios);
  border: 1px solid var(--ticket-blue);
}

.modal-flow::-webkit-scrollbar {
  width: 5px;
  background: #f1f1f1;
}

.modal-flow::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 3px solid rgb(165, 165, 165);
}

/*Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(165, 165, 165);
}
.custom-span {
  border: 0.1px solid #6e84a31f;
  background-color: transparent;
  border-radius: 0.625rem 0 0 0.625rem;
  border-right: none;
  height: 38px;
}

.custom-span.focused {
  border-color: #80bdff;
  line-height: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.35);
  outline: 0;
}

.states-list {
  overflow-y: visible;
  margin-top: 1rem;
}

.no-border {
  border: 0;
  fill: #0050c3;
}

.d-flex.justify-content-start {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
}

ul {
  padding: 0;
  margin-top: 5px;
}

ul li {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  cursor: pointer;
}

ul li i.fas.fa-map-marker-alt {
  padding: 6px;
  margin-right: 5px;
}

.mobile {
  padding-bottom: 0.2rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.4);
}

@media (max-width: 480px) {
  .swal2-container,
  .swal2-modal {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .swall-container,
  .swal2-modal {
    display: flex;
    padding: var(--sweet-alert-padding, 32px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0;
  }
}

#box-terms{
    position: fixed;
    z-index: 100000;
    bottom: 0;
}
#info-terms{
    background-color: black;
    color: white;
    border-radius: 0px;
    padding: 1rem;
    font-size: 0.75rem;
    position: relative;
}
#info-terms a{
    color: #26ad85;
}
#info-terms i{
    font-size: 1rem;
    position: absolute;
    right: 1rem;
    top: 0.75rem;
    color: #26ad85;
}
#info-terms p{
    margin-bottom: 0;
    padding-right: 1rem;
}
#copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#social-icons a {
  font-size: 1.2rem;
  margin-left: 0.5rem;
  display: inline-block;
  margin: 0 5px;
}

.checkout-footer {
  padding-bottom: 4.375rem !important;
}

#footer {
  color: #e9f2f2;
  width: 100%;
  min-height: 4.375rem;
  font-size: 0.875rem;
  position: relative;
}

#footer h2 {
  color: white;
  margin-top: 1rem;
  font-size: 1rem;
}

#footer ul {
  padding-left: 0rem;
}

#footer ul li {
  list-style: none;
}

#footer a {
  color: #e9f2f2;
  display: block;
}

#footer-logo {
  width: 5.625rem;
  margin-bottom: 1.25rem;
}

#footer hr {
  border-top: 0.063rem solid rgba(255, 255, 255, 0.2);
}

.download-button{
  cursor: pointer;
}

@media (min-width: 968px) {
  .button-container {
    grid-gap: 1rem;
    gap: 1rem;
  }
}


@media (max-width: 768px) {
  .footer-phrase {
    margin: 0 auto;
  }

  .button-container {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .download-button {
    width: 8rem;
  }

  .widgets-container {
  justify-content: center;
  margin-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .widgets-container {
    text-align: center;
    grid-gap: 1rem;
    gap: 1rem;
  }
}
.location-container {
  padding: 0.75rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.location-button {
  color: #ffffff;
  font-size: 1rem !important;
  margin-top: 2px;
  background-color: transparent;
  border: none;
  border-radius: 100px;
  display: flex;
  padding: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  min-width: 4rem;
  max-width: 17rem;
  border: none;
}

.location-button span {
  white-space: nowrap;
}

.fas.fa-chevron-down {
  margin-top: 0.25rem;
}
@media (min-width: 992px) and (max-width: 1219px) {
  .location-button {
    max-height: 3.3rem;
    align-items: center;
    grid-gap: 0.2rem;
    gap: 0.2rem;
  }
}

@media (max-width: 991px) {
  .location-container {
    padding: 0;
    cursor: pointer;
  }

  .location-button {
    border: none;
    margin: 0;
    padding-left: 1rem !important;
  }

  .location-button img {
    width: 2.05rem;
  }
}

.card {
  margin-bottom: 1.5rem;
  border-color: #edf2f9;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.modal-footer {
  border-top: none;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal-content {
  margin-top: 105px;
  border-radius: 0rem !important;
  padding: 1.5rem 1.5rem;
}

.modal-content .modal-body {
  min-height: 200px;
  vertical-align: middle;
  padding: 0 1rem;
}

.modal-content .body {
  min-height: 0px;
  vertical-align: middle;
  padding: 0 1rem;
}

.modal-content .new-modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid white !important;
  padding: 1.5rem 1.5rem;
  padding-bottom: 0;
}
.modal-content .new-modal-header img {
  width: 50px;
}
.new-modal-title {
  font-size: 1.5rem !important;
  font-family: "Quicksand";
  letter-spacing: 0;
  color: #4f6c7c;
  opacity: 1;
}

.modal-p {
  font-size: 1rem;
  text-align: center;
  color: #98afbc;
  opacity: 1;
  letter-spacing: 0;
  margin-bottom: 0.5rem;
}

.new-modal-body {
  padding-top: 0;
}
.new-modal-body label {
  font-size: 0.8rem;
  color: #485460;
  font-weight: bold;
}
.new-modal-body .form-control {
  background: #ecf2f7;
  border-color: #ecf2f7;
  border-radius: 0rem;
  padding: 0.5rem 0.75rem;
  height: auto;
}

.new-modal-body .form-control.is-invalid {
  border-color: #dc3545 !important;
}

.new-modal-body .form-control::-webkit-input-placeholder {
  color: #98afbc;
}
.new-modal-body .form-control:-moz-placeholder {
  color: #98afbc;
}
.new-modal-body .form-control::-moz-placeholder {
  color: #98afbc;
}
.new-modal-body .form-control::-ms-input-placeholder {
  color: #98afbc;
}

.btn-logar {
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  color: white;
  background: #26ad85;
  border-radius: 0px;
  opacity: 1;
  border: none;
  cursor: pointer;
}

.btn-logar:disabled {
  background: #19a65857;
  cursor: initial;
}

.btn-logar:disabled:hover {
  background: #19a65857;
}

.login-register-bnt {
  width: 100%;
  height: 50px;
  color: white;
  background: #98afbc 0% 0% no-repeat padding-box;
  border-radius: 0px;
  opacity: 1;
  border: none;
}

.forgot-collapse {
  border-top: 0 !important;
  border-bottom: none !important;
  font-size: 0.8rem;
}

.forgot-collapse .card-header {
  margin-top: 0px;
  background: #fff;
  padding-top: unset;
  padding-bottom: 20px;
  border: none !important;
}

.forgot-collapse .forgot-title span {
  color: #007bff;
  cursor: pointer;
}

.new-modal-footer .account-question {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.new-modal-footer .account-question span {
  position: absolute;
  margin-top: -20px;
  padding: 0.5rem;
  background: transparent;
}

.info-mail {
  color: #26ad85;
}

.btn-send {
  width: 100%;
}

.spinner-border {
  margin-top: 70px;
}

.btn-send i {
  margin-right: 10px;
}
.modal-footer {
  font-size: 0.8rem;
}
.modal-footer button {
  margin-bottom: 3px;
  font-size: 1rem;
}

/* modal configurations */

.close-icon-login {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 20px;
  font-size: 2rem;
  color: #98afbc;
}

.close-icon-login:hover {
  color: #28ae86;
  cursor: pointer;
}

.p-register {
  margin-top: 10px;
  margin-bottom: 0;
}

@media (max-width: 400px) {
  .btn-send {
    margin-top: 20px;
  }
}
.cem{
    width: 100%;
}

.hidden { 
    display:none; 
}

.hidden-success{
    display: none;
}

.react-inputs-validation__msg_identifier{
    color: red !important;
} 

.validation__error___2aXSp{
    border: 1px red solid !important;
}

.close-icon-register{
    cursor: pointer;
}

.close-icon-register:hover{
    color: #000 !important;
}

.checked-register{
    margin-top: 30px;
    width: 150px;
    height: 150px;
}

#header {
  display: flex;
  height: 3.938rem;
  padding: 0.5rem 3.5rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
}

.header-logo {
  width: 9.375rem;
  height: 2.938rem;
  margin-left: 20px !important;
}

.modalBtn,
.location-btn {
  color: white;
  font-size: 1rem;
  background-color: transparent;
  border: none;
}

.location-btn {
  border-radius: 6.25rem;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  border: 0.125rem solid rgba(255, 255, 255, 0.2);
  padding: 0.35rem 1.2rem;
  font-size: 0.9rem;
}

.location-btn:hover {
  background: #ffffff20;
}

#header #dropdown-list {
  display: none;
}

.search-button-container {
  background-color: #95aac9;
  color: #fff;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  cursor: pointer;
}

.search-button-container:hover {
  background-color: #7f91ad;
}

.custom-search-input,
.custom-search-span {
  color: #fff !important;
  border: none;
  background-color: transparent;
  border-radius: 0.625rem;
  height: 2.375rem;
}

.custom-search-input::placeholder {
  color: #fff;
}

.custom-search-input:focus,
.custom-search-input:focus-visible,
.custom-search-span-focused {
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 0.625rem;
  border-right: none;
  height: 2.375rem;
  line-height: 0;
  padding: 0.375rem 0.75rem;
  transition: 0.5ms;
}

.items-container {
  align-items: center;
}

.input-group.search-focus {
  display: flex;
  height: 2.25rem;
  padding-left: 0;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.625rem;
}

.input-container,
.input-container-focus {
  border: 0.006rem solid rgba(255, 255, 255, 0.2);
  border-radius: 0.625rem;
}

@media (max-width: 580px) {
  .navbar-brand img {
    margin-left: 0px;
  }
}

@media (max-width: 991px) {
  #header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
    align-items: center;
    justify-content: space-evenly !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .location-btn img {
    width: 2.2rem;
    height: 2.2rem;
  }

  .items-container {
    justify-content: space-evenly !important;
    align-items: center;
  }

  #header #responsive-navbar-nav {
    margin: 0 -0.5rem -0.5rem;
    background: #0000002b;
  }

  .modalBtn .loginBtn {
    height: 3rem;
  }

  .loginBtn i {
    font-size: 26px !important;
  }

  .login-text {
    display: none;
  }

  #header .dropdown.nav-item,
  #header .dropdown-menu {
    display: none;
  }

  #dropdown-list a,
  #dropdown-list span {
    float: none;
    text-align: right;
    display: block;
    color: white;
    border-bottom: 0.063rem solid #0000001f;
    padding: 0.625rem 1rem;
  }

  #dropdown-list a:last-child {
    border-bottom: none;
  }

  .drop-link:hover {
    background-color: #0000002b;
  }
}

#hearder-margin {
  margin-right: 60px !important;
}

.for-pro {
  text-decoration: underline;
  color: white !important;
  margin-right: 10px;
}

.navbar {
  position: relative;
  display: flex;
}

.location-container-mobile {
  justify-content: center;
  display: flex;
  padding: 0.5rem;
  background-color: #12263f;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}

.location-container-mobile .modalBtn {
  font-size: 0.9rem !important;
}

#header #dropdown-list {
  display: none;
}

@media (max-width: 991px) {
  #header {
    padding: 0.5rem 1rem 0 1rem !important;
  }

  #header .navbar-brand,
  #header button {
    margin-bottom: 0.5rem;
  }

  #header #responsive-navbar-nav {
    margin: 0px -1rem !important;
    background: #0000002b;
  }

  #header .header-list {
    width: 100%;
  }

  #header #responsive-navbar-nav .header-list {
    padding: 0;
  }

  #header .dropdown.nav-item {
    display: none !important;
  }

  #header .dropdown-menu {
    display: none !important;
  }

  #header #dropdown-list {
    display: block;
    padding: 0px;
    margin-bottom: 0;
  }

  #header #dropdown-list a,
  #header #dropdown-list span {
    float: none;
    text-align: right;
    display: block;
    color: white !important;
    border-bottom: 1px solid #0000001f;
    padding: 10px 1rem;
  }

  #header #dropdown-list a:last-child {
    border-bottom: none;
  }

  #header .drop-link:hover {
    background-color: #0000002b !important;
  }

  .modalBtn.loginBtn {
    text-align: right;
    height: 2.2rem;
    font-size: 17px;
    padding-right: 8px;
  }
}

.spacer {
  width: 100%;
}

.full-width {
  width: 100%;
}

/* Resolve desalinhamento do campo de busca no header do site. */
#header .navbar-collapse {
  flex-grow: initial;
  max-width: initial;
  min-width: initial;
}

#header .navbar-brand+.items-container.false.navbar-nav {
  margin-left: auto;
  /* centralizando toda a nav-bar do header */
  flex: 1 0 min-content;
  margin: 0 auto;
  place-content: center !important;
  max-width: 2000px;
  padding: 0 10px !important;
}

#header div.items-container.nav-bar .search-input-container {
  flex: 1 1;
  width: 100% !important;
}

#header .search-input-container {
  width: 100% !important;
  max-width: 900px;
}

#header #responsive-navbar-nav.header-list.navbar-collapse {
  max-width: initial !important;
  min-width: initial !important;
  flex-grow: initial !important;
}
.search-input-container {
  position: relative;
  width: 28rem;
  height: 2.5rem;
}

.search-input-dropdown-container {
  width: 100%;
  position: absolute;
  background-color: #ffffff;
  border-radius: 0.25rem;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0rem 0, 188rem 0.5rem;
  z-index: 1;
}

.search-input-dropdown-content {
  border-bottom: 0.063rem solid #d3d2d9;
  margin-bottom: 0.5rem;
}

.search-input-dropdown-title {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: #17171a;
  margin: 0;
}

.icon-focused {
  border-color: #80bdff;
  outline: 0;
  line-height: 0;
  border-radius: 0.625rem;
  box-shadow: 0 0 0 0.26rem rgba(0, 123, 255, 0.35);
}

.search-input-dropdown-suggestions-container {
  display: flex;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.search-input-dropdown-suggestions-content {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}

.search-input-dropdown-suggestions-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #3a3940;
  margin: 0;
}

.search-input-dropdown-calendar-container {
  display: flex;
  flex-direction: column;
  width: 2.25rem;
  border: 0.063rem solid #f5f5f7;
  border-radius: 0, 25rem;
}

.search-input-dropdown-calendar-month {
  background-color: #f5f5f7;
  color: #767380;
  text-align: center;
}

.search-input-dropdown-calendar-day {
  text-align: center;
  color: #767380;
}

.search-input-dropdown-event-name-container {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
}

.search-input-dropdown-event-name-text {
  color: #525059;
  font-size: 1rem;
  font-weight: 700;
}

.search-input-dropdown-location-container {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.313rem;
  color: #525059;
}

.search-input-dropdown-location-icon {
  padding-right: 0, 25rem;
}

.search-input-dropdown-footer-container {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.search-input-dropdown-footer-button {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: transparent;
  border: none;
  justify-content: flex-end;
}

.search-input-dropdown-footer-button-content {
  display: flex;
  align-items: center;
  height: 1.5rem;
}

.search-input-dropdown-footer-button-text {
  font-weight: 500;
  font-size: 0.875rem;
  padding-right: 0.938rem;
  line-height: 1.313rem;
  color: #0050C3;
  margin: 0;
}

.search-input-dropdown-footer-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
}

.search-input-dropdown-footer-icon {
  padding-right: 0, 25rem;
}

@media (max-width: 768px) {
  .search-input-dropdown-footer-button {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: transparent;
    border: none;
    justify-content: center;
  }

  .mobile {
    display: none;
    border: none;
  }

  .search-input-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 15rem !important;
  }
}

@media (max-width: 991px) {
  .search-input-container {
    margin-bottom: 0.5rem;
  }
}

.language-selector img {
    width: 1.2rem;
    height: 1.2rem;
}

.dropdown-item {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    cursor: pointer;
}

.language-selector {
    padding: 0.7rem;
}

.dropdown-toggle {
    color: #ffff !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    background-color: transparent;
    border: none;
}

.dropdown-menu {
    border-radius: 8px;
    display: flex !important;
    flex-direction: column;
    grid-gap: 9px;
    gap: 9px;
}

.dropdown-menu:hover {
    color: gray;
}

@media (max-width: 991px) {
    .language-body {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
        align-self: center;
        justify-content: center;
    }

    .language-item img {
        width: 1.3rem;
        height: 1.3rem;
        margin-right: 5px;
        margin-bottom: 2px;
    }

    .language-item {
        color: black;
        cursor: pointer;
    }

    .language-selector {
        align-self: flex-end;
        padding: 0;
        display: flex;
    }

    .language-button {
        border: none;
        color: #ffff;
        display: flex;
        align-items: center;
        grid-gap: 5px;
        gap: 5px;
        background-color: transparent;
    }
}

@media (max-width: 550px) {
    .language-body {
        grid-gap: 35px;
        gap: 35px;
        align-self: first baseline;
    }

    .language-modal {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 80%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
    }
}
.dropdown-item {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    cursor: pointer;
}

.options-selector {
    padding: 0.7rem;
}

.dropdown-toggle {
    color: #ffff !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    background-color: transparent;
    border: none;
}

.dropdown-menu {
    border-radius: 8px;
    display: flex !important;
    flex-direction: column;
    grid-gap: 9px;
    gap: 9px;
}

.dropdown-menu:hover {
    color: gray;
}
.terms-approval {
  border: 2px solid #e6f0f6;
  border-radius: 8px;
  padding: 16px 0;
  background: #f1f9f9;
}

.terms-approval span {
  display: block;
}

.terms-approval-title {
  font-weight: 500;
  font-size: 12px;
  color: #525059;
}

.terms-approval-title a {
  text-decoration: underline;
}

.terms-approval-subtitle {
  font-weight: 400;
  font-size: 12px;
  color: #767380;
}

.terms-checkbox {
  width: 16px;
  height: 16px;
  border: 1.5px solid #4f6c7c96;
  border-radius: 3px;
  margin: 5px 0;
  cursor: pointer;
}

.terms-checkbox.accepted {
  background-color: #00a7e5;
  border-color: #00a7e5;
}

.terms-checkmark {
  display: inline-block;
  width: 24px;
  height: 10px; /* IE 9 */ /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.terms-checkmark-stem {
  position: absolute;
  width: 3px;
  height: 12px;
  background-color: #ffffff;
  left: 11px;
  top: 3px;
}

.terms-checkmark-kick {
  position: absolute;
  width: 6px;
  height: 3px;
  background-color: #ffffff;
  left: 7px;
  top: 12px;
}

#page-checkout .card {
  border-radius: 0.5rem;
}

#page-checkout #checkout-eventinfos {
  color: #12263f;
  font-size: 0.85rem !important;
}

#page-checkout .card-header {
  color: #12263f;
}

#page-checkout .card-body,
#page-checkout .card-body .btn {
  font-size: 0.8rem;
}

#page-checkout .form-control {
  background-color: #fff;
  font-size: 1rem;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  border: 1px solid #d2ddec;
}

#page-checkout .form-control.is-invalid {
  border-color: #dc3545;
}

#page-checkout .custom-radio {
  display: flex;
  justify-content: left;
  align-items: center;
  border-color: #edf2f9;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  border-radius: 0.5rem;
  color: #6e84a3 !important;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  margin: 5px 0;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

#page-checkout .pix-method,
#page-checkout .picpay-method {
  margin-left: 0.5rem;
  position: absolute;
  right: 1.75rem;
  width: 1.125rem;
  filter: invert(50%) sepia(39%) saturate(303%) hue-rotate(176deg)
    brightness(93%) contrast(90%);
}

#page-checkout .picpay-method {
  width: 2.5rem;
}

#page-checkout label {
  color: #12263f;
}

#page-checkout .custom-control-input:checked ~ .custom-control-label::before,
#page-checkout .custom-control-input:checked ~ .custom-control-label::after,
#page-checkout .custom-radio .custom-control-label::before,
#page-checkout .custom-radio .custom-control-label::after {
  margin-top: -2px;
}

#page-checkout .cron-clock-icon {
  margin-left: 0.5rem;
  position: absolute;
  right: 1.75rem;
}

#page-checkout .custom-radio label {
  color: #6e84a3 !important;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

#page-checkout .custom-radio .custom-control-input {
  position: relative;
}

.order-resume {
	position: -webkit-sticky;
	position: sticky;
  top: 50px;
  bottom: 30px;
}

.order-resume .card-body {
	max-height: 425px;
	overflow: auto;
	padding-bottom: 20px;
}

.order-resume .order-checkout {
  display: flex;
  justify-content: space-between;
}

.order-checkout .order-items {
  border-bottom: 1px solid #edf2f9;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.order-checkout .order-items-without-divider {
  border: none;
}

.order-checkout .order-items-fixed-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
}

.order-checkout .processing-exclamation-icon {
  position: relative !important;
  right: 0 !important;
  top: 1px !important;
}

.order-checkout .order-items:first-child {
  padding-top: 0rem;
}

.order-checkout .order-items:last-child {
  padding-bottom: 0rem;
  border: 0;
}

.order-resume .card-body {
  font-size: 0.8rem;
}

.order-resume .card-footer {
  font-weight: bold;
  background: transparent;
  font-size: 0.8rem;
}

.order-resume .card-footer .col-12 {
  display: flex;
  justify-content: space-between;
}

#checkout-userinfos {
  background: white;
  border-radius: 0.5rem;
}

#page-checkout #checkout-userinfos.field-alert #checkout-login {
  border: 1px solid #dc3545;
  border-radius: 0.25rem;
}

#checkout-login {
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.checkout-account-icon {
  width: 65px;
  min-width: 65px;
  height: 65px;
  border: 2px solid;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-account-icon i {
  font-size: 40px;
}

#checkout .ticket-detail {
  border-bottom: 1px solid #edf2f9;
  padding-top: 10px;
  padding-bottom: 14px;
}

#checkout .ticket-detail:first-child {
  padding-top: 0rem;
  margin-top: -10px;
}

#checkout .ticket-detail:last-child {
  padding-bottom: 0rem;
  border: 0;
}

.icons-payment {
  margin-left: 20px;
  margin-right: 20px;
}

.boleto {
  margin-left: 20px;
  max-width: 45px;
  max-height: 22px;
}

.footer-payment {
  padding: 20px 0 40px;
}

#page-checkout .card-header {
  font-weight: bold;
  background: transparent;
}

#cron-div {
  background-color: #FDE0E1;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 40px;
  padding: 15px 30px;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
}

#cron-div h4 {
  font-size: 15px !important;
  color: #58151b !important;
}

#cron-div .svg-inline--fa {
  height: 15px !important;
  color: #f53a4c !important;
}

@media (max-width: 1000px) {
  #cron-div {
    width: 100%;
    padding-left: 25px;
  }
}

@media (max-width: 768px) {
  .icons-payment {
    display: none;
  }

  .boleto {
    display: none;
  }

  #cron-div {
    width: 100%;
    padding-left: 25px;
  }

  #cron-div h4 {
    font-size: 20px;
  }

  .btn:hover {
    color: #fff !important;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  #checkout {
    order: 2;
  }

  #page-checkout {
    max-width: 100%;
  }
}

#checkout-login .link {
  color: #6e84a3;
  cursor: pointer;
}

.autofill-checkbox-label {
  display: grid;
  grid-template-columns: 1em auto;
  grid-gap: 14px;
  gap: 14px;
  cursor: pointer;
}

.autofill-checkbox {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  cursor: pointer;
  width: 19px;
  height: 19px;
  border: 2px solid rgb(79 108 124 / 59%);
  border-radius: 3px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.form-check-input {
  width: 15px;
  height: 15px;
}

.input-autofill .form-check-label {
  font-weight: 400;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  accent-color: #0050C3;
}

input[type="radio"] {
  accent-color: #0050C3;
}

.postal-code {
  display: flex;
  align-items: center;
}

.checkout-total {
  padding: 10px 0px 0px 15px;
  font-weight: 700;
  font-size: 14px;
}

.custom-questions {
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.autofill-checkbox:checked {
  background-color: rgb(0, 123, 255);
  border-color: rgb(0, 123, 255);
}

.autofill-checkbox::before {
  content: "";
  width: 11px;
  height: 11px;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: center;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #ffffff;
}

.autofill-checkbox:checked::before {
  transform: scale(1);
}

.autofill-checkbox:focus {
  outline: none;
}

.card-title {
  font-size: 16px;
  font-weight: 700;
  color: #0050C3;
}

.checkout-subtitle {
  font-size: 16px;
  font-weight: 700;
  color: #12263f;
  margin: 5px 0 20px;
}

.event-title {
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}

.card-brand {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}

.card-brand img {
  width: 35px;
  position: absolute;
  right: 24px;
  top: 35px;
}

.prefillContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  background-color: rgb(245, 247, 248);
  border-radius: 4px;
  color: #12263f;
}

.prefillInfoContainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.prefillEditButton {
  outline: none;
  background: none;
  border: none;
  font-size: 0.9rem;
  font-weight: 500;
  color: #26ad85;
}

.prefillAttendeeName {
  font-weight: 600;
  font-size: 1.1rem;
}

.prefillAttendeeEmail {
  font-size: 0.8rem;
}

.summary-accordion {
  box-shadow: none;
  border-radius: 0.5rem;
  border-color: #edf2f9;
  margin-bottom: 1.5rem;
}

.summary-accordion .card {
  margin: 0;
  background-color: #ffffff;
}

.summary-accordion .card .card-header .summary-total {
  display: flex;
  align-items: center;
}

.summary-accordion .card .card-header .summary-total::after {
  content: " ";
  display: none;
  width: 10px;
  height: 10px;
  margin-top: -4px;
  margin-left: 10px;
  border: 1px solid #26ad85;
  border-width: 3px 3px 0 0;
  transform: rotate(135deg);
  transition: all 0.2s linear;
}

.summary-accordion .card .card-header-opened .summary-total::after {
  margin-top: 5px;
  transform: rotate(315deg);
}

@media (max-width: 768px) {
  .summary-accordion .card .card-header .summary-total::after {
    display: block;
  }
}

.order-submit-button {
  background-color: #00c67f;
  border-radius: 8px;
  color: white;
}

.safe-purchase {
  text-align: center;
  padding-top: 7px;
  font-size: 13px;
  display: block;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  #page-checkout {
    margin-bottom: 0 !important;
  }

  /** CRON **/
  div.App div#cron-div {
    position: -webkit-sticky !important;
    position: sticky !important;
    width: 100%;
    padding-left: 25px;
    bottom: auto;
    top: 0;
    margin-top: 0 !important;
    padding: 8px 0 !important;
    height: auto !important;
    background: #fde0e1 !important;
  }

  #cron-div h4 {
    font-size: 13px !important;
    color: #58151b !important;
  }

  #cron-div .svg-inline--fa {
    height: 0.7em !important;
    color: #f53a4c !important;
  }

  #footer.checkout-footer {
    display: none;
  }

  /** CHECKOUT BUTTON **/
  #checkout .justify-content-end.my-4.row {
    background-color: #fff;
    position: -webkit-sticky !important;
    position: sticky !important;
    bottom: -15px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 15px 15px 40px !important;
    box-shadow: 1px -5px 5px 0px rgba(0, 0, 0, 0.2);
  }

  #checkout .justify-content-end.my-4.row>.col-md-12 {
    background-color: #fff;
    padding: 0 !important;
  }

  #checkout .justify-content-end.my-4.row>.col-md-12 .btn {
    padding: 10px !important;
  }

  .safe-purchase {
    text-align: center;
    padding-top: 7px;
    font-size: 13px;
    display: block;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  #checkout .terms-approval {
    margin-bottom: 30px !important;
  }
}

.discount-description {
  border-radius: 0.5rem;
  padding: 1rem 0.5rem 1rem 0.5rem;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  color: #000000;
  display: flex;
  margin: 0;
  width: 65.7%;
  align-items: center;
  margin-top: 0.7rem;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.discount-description.success {
  background-color: #d6f6ea;
}

.discount-description.error {
  background-color: #fdf2d6;
}

.discount-description img {
  margin-right: 12px;
}

@media (max-width: 991px) {
  .discount-description {
    width: 100%;
  }
}

.discount-title {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  text-align: left;
}

.discount-title p,
#coupon-description {
  margin-bottom: 0;
  width: 100%;
  text-align: left;
}

#alert-box-icon {
  width: 1.5rem;
  height: 1.5rem;
}

#arrow-icon {
  width: 0.8rem;
  height: 0.5rem;
  transition: transform 0.2s ease;
}

.rotated-arrow {
  transform: rotate(180deg);
}

[class*="Yuno-card-container"] {
  width: 100% !important;
}

.css-1sivy39 {
  width: 100% !important;
}

#yuno-payment-form > div > div {
  width: 100% !important;
}

.safe-buy-and-terms {
  grid-gap: 24px;
  gap: 24px;
}

.safe-buy {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #525059;
  grid-gap: 8px;
  gap: 8px;
}

.attendee-question-container .lightWeightFont label {
  font-weight: 400 !important;
  padding-top: 2.5px;
}

.attendee-question-container .is-invalid-group {
  border: 1px solid #dc3545;
  padding: 8px 8px 0 8px;
  border-radius: 8px;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: 20px;
}

.attendee-question-container .error-message {
  margin: -0.9rem 0 1rem 0px;
}

#divider-checkout {
    border: 1px solid #d3d2d9;
    width: 100%;
}

#checkout-content {
    padding: 32px 40px;
}

.checkout-card{
    margin: 10px 16px;
}

.pending, .processing {
    border-top: 4px solid #F3AF00;
}

.concluded {
    border-top: 4px solid #00C67F;
}


.error {
    border-top: 4px solid #F53C5A;
}

.icon-header{
    padding-bottom: 1.25rem;
}

.checkout-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #171719;
    margin: 0px;
    max-width: 40rem;
}

.checkout-text, .checkout-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #171719;
    margin: 0px;
    padding: 16px 0px;
    max-width: 40rem;
}

.checkout-description {
    max-width: 40rem;
}

.checkout-tip{
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #3A3940;
    margin-bottom: 5px;
}

.checkout-tip-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #3A3940;
    max-width: 40rem;
}

@media (max-width: 768px) {
    .checkout-informations {
      grid-gap: 0.5rem;
      gap: 0.5rem;
    }

    .checkout-input{
        padding: 0px;
    }

    .checkout-copy-button{
        padding: 0px;
    }
  }
.download-modal-title {
    font-size: 19px;
    font-weight: 700;
    text-align: center;
    display: flex;
    align-self: center;
    width: 88%;
    color: #1C70E9;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.qr-code-download-app {
    width: 223px;
    height: 214px;
}

.modal-body-download-app {
    padding: 1rem;
    display: flex;
    align-self: center;
}

.close-button-download-app {
    background-color: #ffff;
    color: black;
    font-weight: 700;
    border-radius: 0.5rem;
    border-color: #D3D2D9;
    width: 80px;
    height: 40px;
}
.central-text h5 {
    color: black;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
}

.download-app {
    border-radius: 12px;
    background-color: #E6EEF9;
    color: #3A3940;
    grid-gap: 15px;
    gap: 15px;
    align-items: center;
    overflow: visible;
    cursor: pointer;
}

.central-text {
    font-size: 14px;
    text-align: left;
}

.download-now {
    background-color: #0050C3;
    color: white;
    font-weight: 700;
    text-shadow: none;
    margin-top: 5px;
    border-radius: 0.5rem;
}

@media (min-width: 991px) {
    .devices {
        position: absolute;
        top: -14px;
        right: 25px;
    }

    .phrases {
        flex-wrap: wrap;
    }

    .devices-image {
        height: 165px;
    }
}

@media (min-width: 991px) and (max-width: 1066px) {
    .download-app {
        width: 110%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .central-text {
        display: flex;
        width: 60%;
    }

    .devices-image {
        margin-top: 6px;
        height: 189px;
    }
}

@media (min-width: 1200px) and (max-width: 1256px) {
    .central-text {
        width: 70%;
    }

    .download-app {
        width: 110%;
    }
}
.new-input{
    display: flex;
    width: 100%;
    padding: 0.5rem 1rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    border: none;
    border-radius: 0.5rem;
}

.search-container {
    background-color: #fff;
    border-radius:8px;
    border: 1px solid #B7B6BF;
    font-weight: 700;
    padding: 0.5rem; 
}

.search-container input {
  border: none;
  outline: none;
  width: 100%;
}


.button-contained {
  display: flex;
  width: 100%;
  padding: 0.5rem 1rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border: none;
  border-radius: 0.5rem;
}

.primary-contained {
  background-color: #0050C3;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}

.primary-contained:hover {
  background-color: #002d8c;
}

.primary-contained:active {
  background-color: #00145f;
}

.secondary-contained {
  background-color: #fff;
  border-radius:8px;
  border: 1px solid #B7B6BF;
  font-weight: 700;
  cursor: pointer;
}

.secondary-contained:hover{
  background-color: #eae9ed;

}

.secondary-contained:active {
  background-color: #d3d2d9;
}

.button-favorite {
  border-radius: 50% !important;
  width: 3.125rem;
  height: 3.125rem;
  border: none !important;
  background-color: #f6f3f8 !important;
  cursor: pointer !important;
  outline: none !important;
  color: #C30000 !important;
}

.button-favorite i {
  margin: 0 !important;
}

.button-favorite:hover {
  background-color: #220b38;
  border: none;
}

.button-favorite:active {
  background-color: #f6f3f8;
  border: none;
  outline: none;

}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.heartBeatAnimation {
  animation: heartBeat 0.7s ease-in-out;
}

.discount-coupon {
    background-color: white;
}

.discount-coupon-title {
    grid-gap: 8px;
    gap: 8px;
}

.gap-3 {
    grid-gap: 1rem;
    gap: 1rem
}

.height-5 {
    height: 2rem
}

.description-tooltip {
    width: 250px;
    border-radius: 8px !important;
}

.discount-coupon-title span {
    font-weight: 700;
    font-size: 0.875rem;
    color: #17171A !important;
}

.discount-coupon-body {
    display: flex;
    grid-gap: 4px;
    gap: 4px;
    margin: 0;
}

.discount-coupon-body form {
    grid-gap: 8px;
    gap: 8px;
}

.discount-coupon-body button {
    grid-gap: 16px;
    gap: 16px;
    height: 32px;
    display: flex;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #B7B6BF;
    padding: 16px 24px 16px 24px;
    font-size: 0.875rem;
    text-align: center;
    font-weight: 700;
    color: #17171A;
}

.discount-coupon-body button:hover {
    background-color: #0050C3;
    border: none
}

.discount-input-container {
    border-radius: 8px;
    border: 1px solid #767380;
}

.discount-input-container input {
    grid-gap: 16px;
    gap: 16px;
    height: 32px;
    padding: 16px;
    border: none !important;
    background: transparent !important;
    border-radius: 8px;
}


.discount-code-input:focus-visible {
    border: none;
    border-radius: 8px;
    outline: none;
}

.discount-code-input:focus {
    box-shadow: none;
}


.discount-error-message {
    margin: 0;
    color: #F53C47;
    font-size: 12px;
    font-weight: 400;
}

.discount-information-message {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: #17171A;
}

.error-input {
    border: 1px solid #F53C47;
}

.reaply-input {
    border: 1px solid #0050C3;
}

.icon-container {
    border: none;
    display: flex;
    padding-right: 8px;
    border-radius: 8px;
    width: 32px;
}
.preview-container {
  width: 100%;
  height: 400px;
  background-color: #e3ebf6;
  margin-bottom: 32px;
}
.preview-content {
  height: 100%;
  cursor: pointer;
}
.preview-content-header {
  padding-top: 12px;
}
.preview-content-header-text {
  color: #12263f;
  font-weight: 500;
  font-size: 24px;
}
.preview-content-container-img {
  height: 300px;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.preview-content-img {
  height: 100%;
  height: inherit;
  object-fit: cover;
  filter: blur(1px);
}
.preview-content-container-button {
  position: absolute;
  background-color: #12263fbf;
  border-radius: 4px;
  padding: 8px;
}
.preview-content-button {
  border: none;
  background-color: transparent;
  color: #ffffff;
}
.preview-content-container-footer {
  padding-top: 12px;
}
.preview-content-container-footer-text {
  font-weight: 400;
  font-size: 14.4px;
  color: #12263f;
}

.background-produtores {
  width: 100%;
  background: transparent;
  margin: -32px 0;
  padding: 16px 0;
}
.background-produtores h4 {
  color: black;
}

.productor {
  display: flex;
  text-align: right;
  margin-left: auto;
  margin-top: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.location {
  display: flex;
  flex-direction: row;
}

.productor-img {
  background-color: #f8f9fa;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  margin-top: 10px;
  border-radius: 0;
  border: 3px solid #00a7e1;
}
.details {
  width: 100%;

  font: "Light" 17px/20px "Roboto";
  letter-spacing: 0;
  color: black;
  opacity: 1;
  margin-left: 30px;
}
.details .product-links {
  text-decoration: underline;
}
.details ul {
  list-style-type: none;
  min-height: 150px;
}
.details ul a {
  color: black !important;
}
.details ul li:first-child {
  font-weight: bold;
  font-size: 17px;
}
.details ul li:last-child {
  margin-top: 10px;
  position: absolute;
}

.details li {
  word-wrap: break-word;
}

.details h4 {
  word-break: normal;
  margin-top: 10px;
}

.product-links {
  color: #ffffff;
  font-size: 15px;
  font: "Light" 15px/18px "Roboto";
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
}

.more {
  color: #ffffff;
  border: 2px solid #00a7e1;
  border-radius: 11px;
  width: 70%;
}

.productores-btn {
  padding: 5px 40px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.events-btn {
  width: 100%;
  padding: 5px 40px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.productores-btn:hover,
.events-btn:hover {
  color: #ffffff;
  background-color: #00a7e1;
}

@media (max-width: 991px) {
  .productor {
    border-right: none;
  }
}

@media (max-width: 900px) {
  .productor {
    border-right: none;
  }

  .location {
    display: flex;
    flex-direction: row;
  }
  .details {
    padding-left: 6px !important;
  }

  .productor-img {
    width: 110px;
    height: 110px;
    min-width: 110px;
    min-height: 110px;
    margin-top: 25px;
    border-radius: 50%;
  }

  .content-row {
    margin-top: 10px !important;
  }

  .events-btn {
    margin-top: 30px;
    width: auto;
  }

  .more {
    width: 100%;
    background-color: #0050c3;
  }
}

@media (max-width: 370px) {
  .details h4 {
    word-break: break-word;
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .productores-btn {
    padding: 5px 10px !important;
  }
  .details ul {
    padding-left: 10px;
  }
  .details ul li:last-child {
    position: relative;
    bottom: unset;
  }
  .events-btn {
    margin-top: 10px;
  }
}

@media (max-width: 900px) {
  .productor {
    border-right: none;
  }

  .location {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
  }
  .details {
    padding-left: 6px !important;
  }

  .productor-img {
    width: 110px;
    height: 110px;
    min-width: 110px;
    min-height: 110px;
    margin-top: 25px;
    margin-left: -10px;
    border-radius: 0;
  }

  .content-row {
    margin-top: 10px !important;
  }

  .background-produtores {
    padding: 20px 0px;
  }

  .events-btn {
    margin-top: 30px;
    width: auto !important;
  }

  .more {
    width: 100% !important;
    background-color: #26ad85;
  }
}

@media (max-width: 370px) {
  .details h4 {
    word-break: break-word;
    margin-top: 10px;
  }
}
@media (max-width: 768px) {
  .productores-btn {
    padding: 5px 10px !important;
  }
  .details ul {
    padding-left: 10px;
  }
  .details ul li:last-child {
    position: relative;
    bottom: unset;
  }
  .events-btn {
    margin-top: 10px;
  }
}

.button-wrapper {
  padding: 0.5rem 0;
}

.see-location-button {
  background-color: transparent;
  border: none;
  color: white;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #00F0FA;
  cursor: pointer;
  border-radius: 8px;
  max-height: 38px;
  position: relative;
  z-index: 0;
  transition: color 0.3s;
  width: 100%;
}

.see-location span {
  z-index: 10 !important;
}

.see-location-button::after {
  content: '';
  background-color: #00F0FA;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0%;
  overflow: hidden;
  transition: height 0.3s;
  border-radius: 6px;
  z-index: -1;
}

.see-location-button:hover {
  text-decoration: none;
  cursor: pointer;
  color: #12263f;
}

.see-location-button:hover::after {
  height: 100%;
}

.see-location-button:active {
  background-color: #00cbd4;
}
.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 34px;
  height: 34px;
  margin: 5px;
  border-radius: 50%;
  border: 4px solid #26ad85;
  border-color: #26ad85 transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.primary {
  border-radius: 8px;
  background-color: #0050C3;
  border: none;
  padding: 4px;
  width: 100%;
  color: white;
  font-weight: 700;
}

.secondary {
  margin-top: 16px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #d3d2d9;
  padding: 4px;
  width: 100%;
  color: #171719;
  font-weight: 700;
}

.sold-out-modal .modal-body {
  min-height: 0 !important;
}

.sold-out-container {
  margin-bottom: 30px;
}

.sponsor-imgs, .sponsor-group {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.sponsor-logo {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    background: #ffffff;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

.sponsor-group {
    flex-direction: column;
    width: 100%;
    margin-top: 2.0625rem;
    margin-bottom: 2.0625rem;
}

.sponsor-group p {
    color: #12263F;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.sponsor-imgs {
    width: 100%;
    flex-wrap: wrap;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
    grid-gap: 24px;
    gap: 24px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
}
.buttons-loggedOutModal {
  border: none;
  background-color: transparent;
  color: #12263f;
  padding: 8px;
  height: 100%;
}
.buttons-signIn-container .buttons-loggedOutModal:hover {
  color: #ffffff;
}
.buttons-signIn-container {
  border: 1px solid #12263f;
  border-radius: 4px;
}
.buttons-signIn-container:hover {
  background-color: #12263f;
}
.buttons-signIn-container:hover {
  background-color: #12263f;
}
.buttons-continue-container {
  border-radius: 4px;
}
.buttons-continue-container:hover {
  background-color: rgba(18, 38, 63, 0.2);
}
.loggedOut-title {
  color: #12263f;
  font-size: 20px;
  font-weight: 700;
}
.loggedOut-subTitle {
  color: #12263f;
  font-size: 14px;
  font-weight: 500;
}
.loggedOut-information {
  font-weight: 500;
  font-size: 14px;
  color: #95aac9;
}
.loggedOut-timer-clock {
  color: #12263f;
  font-size: 20px;
  font-weight: 700;
}
.loggedOut-timer {
  color: #12263f;
  font-size: 20px;
  font-weight: 700;
  padding-top: 3px;
}

.seats-sidebar {
  background-color: #f6f6f6;
  width: 431px;
  height: 617px;
  right: 0;
  bottom: -10px;
  top: 133px;
  transition-property: width, right;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.seats-sidebar.close {
  width: 0;
  right: -373px;
}

.seats-sidebar-sector {
  border-bottom: 1px solid #d2ddec;
  background: #edf2f9;
}

.seats-sidebar-sector .sector-name {
  color: #12263f;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.seats-sidebar-tickets {
  border-bottom: 1px solid #d2ddec;
}

.seats-sidebar-tickets .ticket-label {
  margin-top: 3px;
  color: var(--essencial-dark, #12263f);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.seats-sidebar-tickets .ticket-type {
  margin-top: 3px;
  color: #95aac9;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.seats-sidebar-tickets
  .ticket-price
  .ticket-original-price
  .ticket-price-discount {
  margin-top: 3px;
  color: #12263f;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.seats-sidebar-tickets .ticket-original-price {
  margin-top: 3px;
  color: #12263f;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-decoration: line-through;
}
.seats-sidebar-tickets .ticket-price-discount {
  margin-top: 3px;
  color: #00d97e;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

@media (max-width: 823px) {
  .seats-sidebar {
    width: 100%;
    top: 131px;
    transition-property: width, right;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
  }

  .seats-sidebar.close {
    width: 0;
    right: -373px;
  }
}
.seats-page-footer {
  background-color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  bottom: 0;
  flex-shrink: 0;
  transition-property: height, up;
  transition-duration: 0.6s;
  transition-timing-function: ease-out;
}

.seats-page-footer-content {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  transition: all 0.5s ease-in;
}

.seats-payment-container {
  display: flex;
  grid-gap: 20px;
  gap: 20px
}

.discount-button {
  background-color: #DEE9E9;
  border-radius: 8px;
  width: 350px;
  height: 46px;
  grid-gap: 2.97px;
  gap: 2.97px;
  flex-shrink: 0;
  white-space: nowrap;
  direction: row;
}
.discount-button .discount-button-text {
  color: #12263F;
  text-align: center;
  font-size: 16px;
}
.discount-button.active .discount-input {
  opacity: 1;
  visibility: visible;
}
.discount-button:hover .discount-input {
  opacity: 1;
  visibility: visible;
}
.discount-input {
  height: 30px;
  opacity: 1;
  visibility: visible;
}
.apply-button {
  height: 30px;
  visibility: visible;
  white-space: nowrap;
}
.payment-button {
  border-radius: 8px;
  display: flex;
  width: 187px;
  height: 46px;
  grid-gap: 10px;
  gap: 10px;
  white-space: nowrap;
  background-color: #12263F;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  cursor: pointer;
  &:active {
      .payment-badge {
          transform: scale(1.2);
     }
 }
}
.payment-button:hover {
  color: white 
}
.payment-badge {
  display: none;
}
.seats-page-cart-value-text {
  color: var(--essencial-dark, #12263F);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}
.payment-badge {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  transition: all .3s;
  background:#00A5E3;
  top: -10px;
  left: -10px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
}
.discount-container {
  transition: all 0.5s ease-in-out;
}
.position-inherit {
  position: inherit;
}
.pointer-none{
  pointer-events: none;
}
.applied-promo-code {
  padding-left: 72px;
  font-size: smaller;
}

@media (min-width: 435px) and (max-width: 823px) {
  .seats-page-footer-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0.5rem 16px;
  }
}
.seats-page-header {
  height: 70px;
  background-color: white;
}

.seats-page-title {
  color: var(--essencial-dark, #12263F);
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  line-break: anywhere;
  transition: all 0.4s ease-in-out;
}

.seats-page-remaining-time {
  color: #183153;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
}

.time {
  color: #183153;
  text-align: end;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
}

@media (max-width: 576px) {
  .seats-page-title {
    color: var(--essencial-dark, #12263F);
    font-size: 14px;

    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    line-break: anywhere;
    transition: all 0.4s ease-in-out;
  }
}

.content-wrapper {
    display: flex;
    flex-direction: column;
  }

.seats-render {
  height: 612px;
  width: 100%;
  flex-shrink: 0;
  transition-property: width, all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100%;
  }

  .w-lg-75 {
    width: 75%;
  }

  .w-lg-50 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .w-md-100 {
    width: 100%;
  }

  .w-md-75 {
    width: 75%;
  }

  .w-md-835 {
    width: 83.5%;
  }


  .w-md-50 {
    width: 50%;
  }
}
#event-header span {
  display: block;
  font-weight: bold;
  font-size: 0.8rem;
}

#event-header span i {
  margin-right: 0.5rem;
}

#event-sidebar-ended {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  grid-gap: -0.01px;
  gap: -0.01px;
  align-self: stretch;
}

#cart-sidebar {
  border-radius: 8px;
  text-align: center;
  color: #708a98;
  font-size: 14px;
  background: #fff;
}

#cart-sidebar #cart-steward {
  color: #0050c3;
  flex: 0 0 100%;
}

#cart-sidebar h4 {
  color: #4f6c7c;
}

#cart-sidebar .ticket {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  border-radius: 8px;
  padding-bottom: 10px;
}

#cart-sidebar #ticket-info {
  text-align: left;
}

#cart-sidebar #ticket-info .ticket-line {
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
}

#cart-sidebar .ticket-name {
  flex: 0 0 100%;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}

#cart-sidebar .ticket-pricing {
  font-size: 14px;
  font-weight: 400;
}

#cart-sidebar .ticket-control button {
  border-radius: 50px;
  height: 18px;
  width: 18px;
  padding: 0px;
  background: #0050c3;
  color: white;
  box-shadow: none !important;
  font-weight: 300;
  font-size: 19px;
  border: none;
  text-align: center;
  line-height: 0;
}

#cart-sidebar .ticket-control button.btn-ticket-list-plus:hover {
  background: #370091;
}

#cart-sidebar .ticket-control button.btn-ticket-list-minus:hover {
  background: #525059;
}

#cart-sidebar .ticket-control button:disabled {
  opacity: 1;
  background: #bacad3;
}

#cart-sidebar .ticket-control .ticket-number {
  margin: auto 10px;
}

#cart-sidebar #ticket-info hr {
  width: 100%;
  margin: 10px auto 0px auto;
}

#cart-sidebar #ticket-info .ticket-line:last-child hr {
  display: none;
}

#cart-sidebar #cart-resume {
  background: white;
  border-radius: 0px 0px 8px 8px;
  border-top: solid #EAE9ED 1px;
}

.discount-component-container {
  border-bottom: solid #EAE9ED 1px;
  background: #ffffff;
  border-top: solid #EAE9ED 1px;
}

.discount-component-container.seats {
  border: solid #eae9ed 1px;
}

#cart-sidebar #discount {
  margin: 5px auto;
  color: #0050c3;
  text-decoration: underline;
}

#cart-sidebar #discount i {
  transform: rotate(-45deg);
}

#cart-sidebar .card {
  border: none;
}

#cart-sidebar .cart-ammount {
  background-color: #fff;
  color: #000;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  font-family: roboto;
  font-weight: 300;
  padding: 10px 0px;
  margin-top: 15px;
}

#cart-sidebar .cart-ammount span {
  font-weight: bold;
}

#cart-sidebar .bt-checkout {
  width: 90%;
  background: #0050c3;
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
  border: none;
  margin-bottom: 20px !important;
  font-weight: 700;
  font-size: 14px;
}

#cart-sidebar .bt-checkout:disabled {
  opacity: 1;
}

.cem {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.ticket h4 {
  margin-top: 15px;
}

#cart-steward {
  margin-top: 20px;
}

#cart-steward {
  color: #0050c3;
}

#ended-sales-card {
  width: 100%;
  border-radius: 8px;
  text-align: center;
  color: #708a98;
  font-size: 14px;
  background: #fff;
  display: flex;
  padding: 0.625rem 0rem 1.125rem 0rem;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}

#ended-sales-card h4 {
  color: #4f6c7c;
}

#ended-sales-card a {
  color: #008cb4;
  font-size: 15px;
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  line-height: 22.5px;
  /* 150% */
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.span-esgotado {
  background: #eae9ed;
  color: #525059;
  padding: 5.5px;
  font-size: 12px;
  border-radius: 8px;
  font-weight: 700;
}

.cupom-descount {
  border-radius: 0px !important;
  margin-bottom: 0 !important;
}

.force-center {
  text-align: center !important;
}

.sectors-accordion {
  margin-top: 12px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.sectors-accordion .card {
  margin: 0;
  background-color: #f8f8f8;
}

.sectors-accordion .card .card-header {
  background: #ffffff;
  margin-bottom: 0;
  cursor: pointer;
  color: #12263f;
  font-weight: 500;
  min-height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sectors-accordion .card .card-header span {
  display: block;
  margin-right: 50px;
}

.sectors-accordion .card .card-header .status {
  display: flex;
  align-items: center;
}

.sectors-accordion .card .card-header .status .sectorAmount {
  margin-right: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  height: 28px;
  min-width: 28px;
  padding: 1px 6px 0;
  text-align: center;
  border-radius: 30px;
  color: #fff;
  background: #0050C3;
}

.sectors-accordion .card .card-header .status::after {
  content: " ";
  width: 7px;
  height: 7px;
  margin-top: -2px;
  border: 1px solid #0050C3;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
  transition: all 0.2s linear;
}

.sectors-accordion .card .card-header-opened .status::after {
  transform: rotate(135deg);
}

.sectors-accordion .collapse .card-body {
  background-color: #f8f8f8;
  box-shadow: inset 0 0 0.0625rem 0 #d4d7d9;
  border-radius: 0 !important;
}

.unavailable-tickets {
  text-align: center;
  padding: 30px 0 0;
  font-size: 1.4rem;
}

@media (max-width: 768px) {
  #event-content #cart-sidebar #ended-sales-card {
    order: 1;
    margin-bottom: 30px;
  }

  #event-sidebar {
    padding: 0;
  }
}

@media (max-width: 500px) {
  #cart-sidebar .ticket {
    border-radius: 8px;
  }
}

@media (max-width: 400) {
  .accordion {
    border: none !important;
  }
}

.ticket-warning {
  font-size: 12px;
  color: red;
}

.text {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.text:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 0%, #f8f9fa 100%);
  pointer-events: none;
  z-index: 1;
}
.read-more {
  z-index: 2;
  -webkit-line-clamp: unset;
  overflow: visible;
}

.read-or-hide {
  cursor: pointer;
  margin-top: -20px;
  z-index: 3;
}

.text.read-more:before {
  display: none;
}

.text.read-more .read-or-hide {
  box-shadow: none;
}

.see-more {
  cursor: pointer;
  color: #008cb4;
}

.see-more-button {
  height: 56px;
  padding: 16px, 110.9px, 16px, 111.1px;
  border-radius: 50px;
  background-color: #0050C3;
  color: white;
}

.see-more-gap {
  grid-gap: 2rem;
  gap: 2rem;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
}

.seats-render {
  width: 100%;
  height: calc((100vh) - 340px);
  flex-shrink: 0;
  transition-property: width, all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100%;
  }

  .w-lg-75 {
    width: 75%;
  }

  .w-lg-50 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .w-md-100 {
    width: 100%;
  }

  .w-md-75 {
    width: 75%;
  }

  .w-md-835 {
    width: 83.5%;
  }

  .w-md-50 {
    width: 50%;
  }
}


#productor-head{
    width: 100%;
    height: 320px;
}

.productor-avatar{
    position: relative;
    left: calc(50% - 75.5px);
    margin-top: -100px;
    z-index: 100;
    width: 150px;
    height: 150px;
    border: 5px solid white;
    border-radius: 50%;
    background: white;
}

.background{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.proctor-name{
    padding-top: 20px;
    font-weight: bold;
}

.title-productor {
    width: 100%;
    padding: 10px 0px;
}

.ajust-center {
    min-height: 720px;
    margin-top: 20%;
}

.load-more-button {
    height: 56px;
    padding: 16px 110.9px 16px 111.1px;
    border-radius: 50px;
    background-color: #0050C3;
    color: white;
  }
#faqs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 340px;
  padding-bottom: 40px;
}

#faqs h3 {
  margin-top: 30px;
}

#faqs .blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #edf2f9;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
#faqs .blocks:hover {
  background: #e9f2f2;
}

#faqs .blocks p {
  margin-top: 15px;
  margin-left: 20px;
  font-size: 0.9rem;
  font-weight: bold;
  color: #4f6c7c;
}
#faqs .blocks img {
  width: 25px;
}
#faqs .blocks-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: transparent;
  margin-top: 50px;
}

#faqs .blocks-btn button {
  width: 300px;
  height: 46px;
  background: #26ad85;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

#container-question{
    width: 100%;
    min-height: 600px;
    margin-top: 0px;
    margin-bottom: 50px;
}
#help-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 240px;
  background: #d8e2e7;
}

#help-header h3 {
  margin-top: 30px;
}

#help-header input {
  width: 700px;
  height: 50px;
  margin-top: 20px;
  border-radius: 9px;
}

#helper-body {
  position: relative;
  width: 100%;
  min-height: 600px;
  background: #f8f9fa;
  z-index: 1000;
}

#helper-body .blocotes {
  top: -50px;
  z-index: 9999;
  padding: 40px 20px;
  box-shadow: 0px 4px 12px #2a546626;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  background: #ffffff;
}

#helper-body .b-1 {
  margin-left: 90px;
}

#b2 {
  margin-left: 10px;
}

#helper-body .blocotes .blocote-icon {
  position: relative;
  left: 150px;
  top: -110px;
  z-index: 999;
  background: #ffffff;
  width: 140px;
  height: 140px;
  border: 3px solid #0050c3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blocotes .blocote-content {
  margin-top: -80px;
}

.blocotes .blocote-content h5 {
  font-size: 28px;
  font: "Regular" 28px/33px "Quicksand";
  color: #4f6c7c;
  opacity: 1;
}

.blocotes .blocote-content .f-question {
  width: 100%;
  min-height: 70px;
  margin-top: 50px;
  background: #d8e2e7;
  border-radius: 5px;
}

.blocotes .blocote-content .f-question img {
  padding-top: 15px;
  padding-left: 20px;
}

.blocotes .blocote-content .f-question p {
  color: #4f6c7c;
  padding-top: 15px;
  line-height: 15px;
}

#helper-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 340px;
  padding-bottom: 40px;
  background: #bacad3;
}

#helper-footer h3 {
  margin-top: 30px;
}

#helper-footer .blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  background: #e9f2f2;
  border-radius: 5px;
  cursor: pointer;
}

#helper-footer .blocks p {
  margin-top: 15px;
  margin-left: 20px;
  font-size: 19px;
  color: #4f6c7c;
}

#helper-footer .blocks-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: transparent;
  margin-top: 50px;
}

#helper-footer .blocks-btn button {
  width: 300px;
  height: 46px;
  background: #26ad85;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

@media (max-width: 499px) {
  #help-header input {
    width: 90%;
    height: 50px;
    margin-top: 20px;
    border-radius: 9px;
  }

  #helper-body .b-1 {
    margin-left: 0px;
  }

  #b2 {
    margin-left: 0px !important;
    top: 50px !important;
  }

  #helper-body .blocotes .blocote-icon {
    left: 100px;
  }

  #helper-footer {
    margin-top: 90px;
  }

  #helper-body .blocotes .blocote-icon {
    left: 30%;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  #help-header input {
    width: 90%;
    height: 50px;
    margin-top: 20px;
    border-radius: 9px;
  }

  #helper-body .b-1 {
    margin-left: 10px;
  }

  #helper-body .blocotes .blocote-icon {
    left: 38%;
  }

  #b2 {
    margin-left: 0px !important;
    top: 50px !important;
  }

  #helper-footer {
    margin-top: 90px;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  #helper-body .blocotes .blocote-icon {
    left: 23%;
  }

  #helper-body .b-1 {
    margin-left: 55px;
  }

  .blocotes .blocote-content .f-question p {
    margin-left: 10px;
  }
}

@media (min-width: 1001px) and (max-width: 1200px) {
  #helper-body .blocotes .blocote-icon {
    left: 100px;
  }
}

#faq-nav{
    background: #6e84a3;
    color: white;
    font-size: 0.9rem;
    border-top: 1px solid #ffffff1f;
    padding: 1rem 0;
}
#faq-nav a{
    color: white;
}
.single-faq{
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    min-height: 320px;
}
.single-faq h1{
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #12263f;
}
.single-faq p{
    font-size: 0.9rem;
}
.carousel-banner {
  cursor: pointer;
}

.banner-full {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-color: #0050c3;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 500px) {
  .carousel-inner {
    height: 380px;
  }
}


.carousel .carousel-control-next,
.carousel .carousel-control-prev {
  width: 7%;
}
.banner-infos div {
  display: flex;
  flex-direction: column;
}
.banner-infos h2 {
  font-size: 1.75rem;
  word-break: break-all;
  color: white !important;
  order: 2;
  text-transform: uppercase;
  margin-top: 1rem;
}
.banner-infos .banner-place {
  font-size: 0.75rem !important;
  color: white !important;
}

.banner-infos .nav-link-btn {
  position: relative;
}

.event-card .banner-place i {
  margin-right: 5px;
  color: white !important;
}

.home-top-container {
  grid-gap: 1rem;
  gap: 1rem;
}

@media (max-width: 500px) {
  .banner-infos .nav-link-btn {
    display: none;
  }

  .carousel .carousel-control-next,
  .carousel .carousel-control-prev {
    height: 50%;
  }

  .secondary-highlights {
    margin-top: -2.5rem;
  }
}

@media (max-width: 768px) {
  .input-filter-container {
    padding-bottom: 8px;
  }
}
.event-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 750px;
}

.event-loader p {
    font-size: 18px;
}

.event-loader h4 {
    font-style: italic;
}

.loader-icon {
    margin-bottom: 1rem;
}
.nominal-transfer {
  padding-bottom: 160px;
}

.nominal-transfer .title {
  grid-gap: 24px;
  gap: 24px;
}

.nominal-transfer .body {
  grid-gap: 24px;
  gap: 24px;
}

.nominal-transfer div h3 {
  color: #17171a;
  font-size: 1.5rem;
}

.nominal-transfer .body form div label {
  font-size: 14px;
  font-weight: 700;
  color: #171719;
}

.nominal-transfer .body form div input {
  height: 56px;
  border: 1px solid #767380;
  background-color: #ffffff;
}

.nominal-transfer .body form Button {
  border-radius: 0.5rem;
  padding: 16px 24px 16px 24px;
  border: 1px solid #767380;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  grid-gap: 1rem;
  gap: 1rem;
}

Button:active {
  background-color: none;
}

.continue {
  background-color: #0050c3;
  border: none !important;
}

.ticket-transfer-button:hover,
.ticket-transfer-button:focus,
.ticket-transfer-button:disabled,
.continue:focus .continue:hover {
  background-color: #003684;
}

.continue:disabled {
  box-shadow: none;
  background-color: #d3d2d9;
  border: none !important;
  color: #767380 !important;
  font-weight: 700;
}

.cancel-transfer {
  background-color: transparent;
  border: 1px solid #b7b6bf;
  color: #17171a;
  font-weight: 700;
  font-size: 14px;
  border-radius: 0.5rem;
}

.edit-email:hover,
.edit-email:active,
.cancel-transfer:active,
.cancel-transfer:hover {
  color: #17171a !important;
  background-color: #eae9ed !important;
  border: 1px solid #d3d2d9 !important;
}

Button:disabled {
  background-color: #d3d2d9;
  color: #767380;
}

#nominal-transfer-hr {
  background-color: #85abe3;
  opacity: 24%;
  height: 1px;
}

Button.edit-email {
  background-color: transparent;
  padding: 8px 24px 8px 24px;
  border: 1px solid #b7b6bf;
  color: #17171a;
  font-weight: 700;
  font-size: 0.875rem;
  border-radius: 0.5rem;
}

.ticket-data-review {
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.5rem;
}

.ticket-data-review div p.ticket-name {
  font-size: 14px;
  -webkit-line-clamp: 2;
  font-weight: 700;
  color: #17171a;
}

.ticket-data-review div p {
  font-size: 14px;
  color: #3a3940;
}

.ticket-data-review div p {
  padding: 0;
  margin: 0;
  line-height: 16px;
}

.ol-ellipsis {
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.tl-ellipsis {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.data-review-container {
  background-color: #e6eef9;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
  border-radius: 0.5rem;
}

.redeem-instructions-container {
  border-radius: 1.5rem;
  border: 1px solid #eae9ed;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.redeem-instructions-text {
  font-size: 14px;
  color: #3a3940;
  font-weight: 400;
  line-height: 14px;
}

.ticket-transfer-button {
  background-color: #0050c3;
  font-weight: 700;
  font-size: 14px;
  border-radius: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border: none;
}

.buttons-container {
  grid-gap: 1rem;
  gap: 1rem;
}

#ReceiverEmail.form-text {
  color: #171719;
  font-weight: 400;
  font-size: 12px;
}

.ticket-exhibit {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.5rem;
  background-color: #e6eef9;
}

.ticket-exhibit div p.ticket-name {
  font-size: 14px;
  -webkit-line-clamp: 2;
  font-weight: 700;
  color: #17171a;
}

.ticket-exhibit div p {
  font-size: 14px;
  color: #3a3940;
}

.ticket-exhibit div p {
  padding: 0;
  margin: 0;
  line-height: 16px;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.ticket-name-event-container {
  grid-gap: 12px;
  gap: 12px;
}

.nominal-transfer-modal {
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.nominal-transfer-instruction-title {
  color: #17171a;
  font-size: 18px;
}

.transfer-instructions-item {
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-direction: row;
}

.transfer-instructions-item div > p {
  color: #3a3940;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 16px;
  padding: 0;
  margin: 0;
}

.nominal-transfer-modal button {
  background-color: #0050c3;
  border-radius: 0.5rem;
  padding: 16px 24px 16px 24px;
}

.unplanned-container {
  grid-gap: 2rem;
  gap: 2rem;
}

.unplanned-text-container {
  grid-gap: 1rem;
  gap: 1rem;
}

.unplanned-title h3 {
  font-weight: 700;
  font-size: 18px;
  color: #17171a;
}

.unplanned-body {
  font-size: 14px;
  font-weight: 400;
  color: #525059;
}

.unplanned-modal {
  grid-gap: 2rem;
  gap: 2rem;
}

.success-modal {
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.success-modal-title {
  font-weight: 700;
  font-size: 1.5rem;
  color: #17171a;
}

.success-modal-body {
  padding: 0;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  font-size: 14px;
  font-weight: 400;
  color: #3a3940;
}

.success-modal-button {
  background-color: #0050c3;
  font-weight: 700;
  font-size: 14px;
  border-radius: 0.5rem;
  border: none;
}

.success-modal-button:disabled {
  background-color: #d3d2d9;
  color: #767380;
}

.success-modal-button:active {
  background-color: none;
}

* {
    box-sizing: border-box;
  }
  
  body {
    padding: 0;
    margin: 0;
  }
  
  #notfound {
    position: relative;
    height: 100vh;
  }
  
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
  }
  
  .notfound .notfound-404 {
    position: relative;
    height: 200px;
    margin: 0px auto 20px;
    z-index: -1;
  }
  
  .notfound .notfound-404 h1 {
    font-size: 236px;
    font-weight: 200;
    margin: 0px;
    color: #211b19;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .notfound .notfound-404 h2 {
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;
    color: #211b19;
    background: #fff;
    padding: 10px 5px;
    margin: auto;
    display: inline-block;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
  }
  
  .notfound a {
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    padding: 13px 23px;
    background: #ff6300;
    font-size: 18px;
    transition: 0.2s all;
  }
  
  .notfound a:hover {
    color: #ff6300;
    background: #211b19;
  }
  
  @media only screen and (max-width: 767px) {
    .notfound .notfound-404 h1 {
      font-size: 148px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
      height: 148px;
      margin: 0px auto 10px;
    }
    .notfound .notfound-404 h1 {
      font-size: 86px;
    }
    .notfound .notfound-404 h2 {
      font-size: 16px;
    }
    .notfound a {
      padding: 7px 15px;
      font-size: 14px;
    }
  }
  
#form-header{
    padding-top: 20px;
    margin-bottom: 50px;
    min-height: 300px !important;
}
.container h3{
    padding-top: 50px;
}

.new-pass{
    margin-top: 13px;
}

.btn-send-reset{
    width: 50%;
}

.btn-send-reset i{
    margin-right: 10px;
}
.near-events-searched {
  padding-left: 15%;
}

.error-container {
  text-align: center;
}

.main-title {
  color: #283e59;
  font-size: x-large;
}

.description {
  font-size: medium;
}

.error-container {
  margin: 3rem 0;
}

@media (max-width: 768px) {
  .near-events-searched {
    padding-left: 0;
  }
}


.important-law{
    border-left: 3px solid gray;
    margin-left: 20px;
    padding-left: 20px;
}

#page-myevents {
  margin-bottom: 100px !important;
  margin-top: 40px !important;
  min-height: 400px;
}

#page-myevents a {
  text-decoration: none !important;
  color: #839dac;
}

#page-myevents a:hover {
  color: #007bff;
}

#page-myevents a:hover .myevents-card {
  background-color: #ffffffba;
}

#page-myevents a:hover .event-date {
  background: #007bff;
}

#page-myevents .event-date {
  margin-top: 0;
  margin-right: 1rem;
  position: relative;
  left: auto;
  top: auto;
}

#page-myevents .tickets h2 {
  text-align: center;
  padding-top: 10px;
  margin-bottom: 50px;
}

.myevents-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  padding: 0.3rem;
  padding-right: 1.5rem;
}

.myevents-card p {
  margin: 0;
}

.myevents-event-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

#blocotes .data {
  width: 57px;
  height: 77px;
  background: #0050c3 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  color: #ffffff !important;
  text-align: center;
}

#blocotes .data h3 {
  color: #ffffff !important;
}

#blocotes .logo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  justify-content: center;
  width: 69px;
  height: 69px;
  border-radius: 42px;
  opacity: 1;
}

#blocotes .logo img {
  width: 60px;
  height: 60px;
}

#blocotes .event {
  min-width: 350px;
  margin-left: 10px;
  margin-right: 10px;
}

#blocotes .event h5 {
  color: #0050c3 !important;
}

#blocotes .copy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 57px;
  height: 77px;
}

#blocotes .copy .icon {
  width: 35px;
  height: 36px;
  border-radius: 50%;
  background-color: #4f6c7c;
}

.border-link {
  border: none;
  border-radius: 25px;
  color: #0050c3;
  width: 30px;
  height: 30px;
  transition: all 0.5s;
}

.border-link:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

#page-mytickets {
  margin-bottom: 100px !important;
  margin-top: 40px !important;
  min-height: 400px;
}

.avaliable-on-app-text{
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #171719;
}

#page-mytickets h2 {
  margin-right: 1rem;
}

#page-mytickets .page-header .event-search {
  flex-grow: 1;
}

#page-mytickets .tickets-event-header {
  display: flex;
  align-items: center;
}

#page-mytickets .tickets-event-header .event-date {
  margin-top: 0;
  margin-right: 1rem;
  position: relative;
  left: auto;
  top: auto;
}

#page-mytickets .ticket-orders {
  color: #525059;
  border-bottom: 1px solid #edf2f9;
  margin-top: 10px;
  display: flex;
}

#page-mytickets .ticket-orders:first-child {
  margin-top: 0;
}

#page-mytickets .ticket-orders:last-child {
  border-bottom: 0;
}

#page-mytickets .ticket-orders:not(:last-child) {
  padding-bottom: 1rem;
}

.ticket-location {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.button-has-more {
  border-radius: 20px !important;
  background-color: #0050c3 !important;
  color: white !important;
  font-size: 16px !important;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem !important;
  font-weight: 400 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 128px !important;
  height: 40px !important;
}

.ticket-location p {
  font-size: 1rem;
  font-weight: lighter;
  letter-spacing: 0;
  color: #839dac;
  opacity: 1;
}

.event-title-tickets {
  color: black;
}

.btn-link {
  margin-top: -10px;
  float: right;
  color: #839dac;
}

.arrows {
  margin-left: 10px;
}

.ticket-card {
  margin-bottom: 40px;
}

.card-header-config {
  background: #98afbc !important;
  color: white !important;
  border-radius: 8px !important;
}

.header-collapsed {
  background: #98afbc !important;
  color: white !important;
  border-radius: 8px 8px 0px 0px !important;
}

.card-collapser {
  border-radius: 8px !important;
  border-top: #98afbc !important;
  border-bottom: #98afbc !important;
  border-radius: 8px 8px 0px 0px !important;
  border-right: white !important;
  border-left: white !important;
}

.collapse .card-body {
  border-radius: 0px 0px 8px 8px !important;
}


/* .see-ticket-button{
  max-width: 210px;
} */

.card-body {
  padding: 1rem;
}

.card-header-config {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
}

.card-header-config .btn {
  color: white;
}

.card-acordion {
  border-right: white !important;
  border-left: white !important;
  border-radius: 0 !important;
}

.btn-ticket {
  background: transparent;
  border-radius: 0.5rem;
  padding: 1px 1.5px;
  min-width: 136px;
  height: 40px;
  display: flex;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  font-size: 14px;
  font-weight: 700;
  color: #17171a;
  border: 1px solid #b7b6bf;
}

.btn-ticket:focus,
.btn-ticket:hover,
.btn-ticket:active {
  color: #17171a !important;
  background-color: #eae9ed !important;
  border: 1px solid #d3d2d9 !important;
}

.my-tickets-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.icon-rotate {
  transform: rotate(-45deg);
}

#basic-addon1 {
  background-color: white !important;
}

.empty-tickets {
  min-height: 200px;
}

.empty-tickets img {
  width: 100px;
  height: 100px;
  margin-top: 50px;
}

.transferred-ticket {
  background-color: #eae9ed;
  border-radius: 14px;
  font-weight: 700;
  color: #3a3940;
  font-size: 15px;
}

.transferred {
  background-color: #eae9ed;
  border-radius: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.875rem;
}

.transfer-canceled {
  background-color: #ffe1d7;
  border-radius: 1rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #730a46;
}

@media (max-width: 768px) {
  .btn-link {
    order: 2;
    width: auto;
    text-align: right !important;
  }

  .card-header-config {
    display: flex;
    flex-direction: column;
  }

  .event-name {
    width: 300px;
  }

  .event-title-tickets {
    font-size: 22px;
  }

  .event-search {
    margin-top: 15px;
  }

  .badge-pill {
    margin-bottom: 5px;
  }

  .show-tickets-dropdown {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  .order-token-ticket {
    font-weight: 700;
  }
}

@media (max-width: 400px) {
  .card-header {
    padding-left: 20px !important;
  }

  .card-header-config .btn {
    margin-top: 5px;
  }
}

#orders-card{
    margin: 2rem 0;
    border-radius: 0.5rem;
    overflow: hidden;
}
.card.card-myorders{
    color: #12263f;
    font-size: .8rem;
    margin-bottom: 0;
    border: 0;
    border-bottom: 1px solid #edf2f9;
}
.card-myorders .btn-link{
    color: #12263f;
    margin-top: 0;
    text-align: right;
    font-size: .8rem;
}
.card-myorders .order-icon-payment svg{
    font-size: 1.2rem;
    color: #95aac9;
}
.order-eventdetail{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.order-card-header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: white !important;
}

.order-card-header .text-muted svg{
    color: #d9e7ef !important;
}
.card-myorders .collapse, .card-myorders .collapsing{
    background: #f7fbff;
    border-top: 1px solid #edf2f9;
}
.user-header {
  margin-top: 40px;
}

.cem {
  width: 100%;
}

.cem i {
  margin-right: 10px;
}

@media (max-width: 400px) {
  .user-header {
    margin-top: 20px;
  }
}

div.form-group label {
  display: block;
  color: #485460;
  font-size: 12.8px;
  font-weight: 700;
  line-height: 19.2px;
}

div.form-group input {
  padding: 11px, 13px, 12px, 13px;
  background-color: #ecf2f7;
  border-radius: 8px;
  border: 1px solid #ecf2f7;
}

div.form-group button.edit-button {
  margin-top: 1.9rem;
}

.edit-button {
  color: white;
  font-size: 16px;
  line-height: 24px;
  background-color: #5532e0;
  padding: 13px, 188.91px, 13px, 189.09px;
}

.edit-button:hover {
  color: white;
  background-color: #0050C3;
}

nav.nav-tabs a {
  font-weight: 400;
  font-size: 24px;
  line-height: 38.4px;
  color: #98afbc;
  border: none !important;
  background-color: transparent !important;
  border-radius: 0 !important;
}

nav.nav-tabs a.active {
  color: #0050C3 !important;
  font-weight: 500;
  border-bottom: 4px solid #0050C3 !important;
}
#header {
  background: black !important;
}
#header .navbar-brand img {
  width: 145px;
  margin-top: -5px;
}

.event-date {
  background: #26ad85;
}

.event-card:hover .event-date {
  background: #0f4946;
}

h1,
h2,
h3,
h4 {
  color: #26ad85;
}

#main-bar {
  background: #3c5255;
}

#near-events #more-events:hover {
  background: rgb(52, 52, 52);
}

#footer {
  background: #3c5255;
}

.drop-link {
  text-decoration: none !important;
  color: #26ad85 !important;
}

.drop-link:hover,
.drop-link.active {
  background-color: #26ad85 !important;
  color: white !important;
}

.nav-link-btn {
  background-color: white !important;
}

.nav-link-btn:hover {
  background: #0f4946 !important;
}

.btn-logar {
  background: #26ad85;
}

.btn-logar:hover {
  background: #0f4946;
}
.btn-down-ticket {
  color: #26ad85;
  border: #26ad85;
}
#cart-sidebar #cart-resume {
  background: #13171c !important;
}
.background-produtores {
  color: #13171c !important;
}
#cart-sidebar .bt-checkout {
  background-color: #0f4946 !important;
}
#cart-sidebar .bt-checkout:hover {
  background: #252b32 !important;
}
.background-produtores h4 {
  color: black;
}

.productor-img,
.more {
  border-color: #a9a9a9;
}

#sub-search {
  background: #a9a9a9;
}

.productores-btn:hover,
.events-btn:hover {
  background-color: #a9a9a9;
}

.drop-link {
  color: #26ad85 !important;
}

#drop-down,
#faqs .blocks-btn button,
.banner-infos button,
.banner-infos .share a:hover,
.drop-link:hover,
.drop-link.active {
  background-color: #26ad85;
}

#header #create-event,
.banner-infos button:hover {
  background-color: #26ad85;
}

.footer-tel {
  color: #26ad85;
}

.event-card .event-name {
  color: #26ad85;
}

#cart-sidebar .bt-checkout:hover {
  background: black;
}

.info-mail {
  color: #26ad85;
}

#cart-sidebar .ticket-control button,
#cart-sidebar .bt-checkout,
#helper-footer .blocks-btn button {
  background-color: black;
}
#footer-logo {
  width: 129px !important;
  margin-bottom: 20px !important;
}

@media (min-width: 992px) {
  #header #create-event {
    border-color: #4e0d52;
  }
  #header #create-event:hover {
    background: #4e0d52;
  }
}

/* LOADING */
#loading {
  background-color: #fffffff5;
}

#loading-border {
  border-color: #26ad85;
}

#loading-icon span {
  background-color: #26ad85;
}

#loading p:nth-child(2) {
  color: #26ad85;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
    color: #26ad85;
  }

  50% {
    transform: rotate(180deg);
    color: #26ad85;
  }

  100% {
    transform: rotate(359deg);
    color: #26ad85;
  }
}

@media (min-width: 992px) {
  #header #create-event {
    border-color: #26ad85;
  }

  #header #create-event:hover {
    background: #26ad85;
  }
}

.swal2-confirm {
  background-color: #0050c3 !important;
}

.swal2-confirm:hover {
  background-color: #003e9e !important;
}

p {
  margin: 0px;
}

.popup-modal {
  display: static;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 0;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin: 0;
  border: none;
  z-index: 100000;
  background-color: #ffffff;
  border-radius: 4px 4px 0 0;
}

.popup-modal .popup-modal-content {
  margin: 20px 0;
  padding: 0 16px;
}

.popup-modal-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  z-index: 100000;
}

.zig-icon {
  width: 5rem;
  height: 5rem;
}

.download-title {
  font-weight: 700;
  font-size: 1.125rem;
  max-width: 15.938rem;
  line-height: 1.688rem;
  color: #17171a;
  text-align: center;
}

.download-quantity-text,
.arguments-text {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #3a3940;
}

